import React, { useState, useEffect, useContext } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DetalheParcela from "../../contas/DetalheParcela";
import DetalheTransferencia from "../../transferencia/DetalheTransferencia"
import Lancar from "../../contas/Lancar"
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MessagesContext from "hooks/MessagesContext";
import TabelaPadrao from "componentes/TabelaPadrao";
import { Grid, Icon, Tooltip, Divider, Menu, MenuItem, Card, Box } from "@mui/material";
import AuthContext from 'hooks/AuthContext';
import { formatDate } from "commons/utils";
import { formataValor } from "commons/utils";
import Caixa from '../../../../assets/images/bank/nao-e-conta-corrente.svg'
import Bank from '../../../../assets/images/bank/outro-banco.svg'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { startOfWeek, endOfWeek, addMonths, endOfMonth, startOfMonth, startOfYear, endOfYear, subMonths } from 'date-fns'
import format from 'date-fns/format'
import { ptBR } from "date-fns/locale";
import FormField from "componentes/FormField";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DialogoConfirmaValidacao from 'componentes/DialogoConfirmaValidacao';



export default function DialogoExtrato(props) {

    const { open, handleCancel, title, handleClickTransferencia, handleClickDespesa, handleClickReceita,
        recarregaTabela, setRecarregaTabela, dia_vencimento, dia_fechamento } = props;


    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera, MensagemAlerta } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    /*****************Menu Item*******************/
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDetalhe, setOpenDetalhe] = useState(false);

    const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
    const [diaAtual, setDiaAtual] = useState(new Date());
    const [faturaPaga, setFaturaPaga] = useState(false);
    const [idFatura, setIdFatura] = useState(0);
    const [filtro, setFiltro] = useState(
        {
            labelOriginal: endOfMonth(new Date()),
            labelFormatada: format(endOfMonth(new Date()), "MMMM 'de' yyyy", { locale: ptBR }),

            dataInicialOriginal: startOfMonth(new Date()),
            dataInicialFormatada: (format(startOfMonth(new Date()), "yyyy-MM-dd 00:00:00", { locale: ptBR })),

            dataFinalOriginal: endOfMonth(new Date()),
            dataFinalFormatada: (format(endOfMonth(new Date()), "yyyy-MM-dd 23:59:59", { locale: ptBR })),

            descricaoFatura: `Pagamento fatura ${format(endOfMonth(new Date()), "MMMM 'de' yyyy", { locale: ptBR })}`
        }
    );
    const [valorFatura, setValorFatura] = useState(0);
    let objDataInicialFiltro = {
        original: startOfMonth(new Date()),
        formatada: (format(startOfMonth(new Date()), "yyyy-MM-dd 00:00:00", { locale: ptBR }))
    }

    const [detalheParcelaOpen, setDetalheParcelaOpen] = useState({ visible: false, nome: '' });
    const [detalheLancar, setDetalheLancar] = useState({ visible: false, nome: '' });
    const [detalheTransferenciaOpen, setDetalheTransferenciaOpen] = useState({ visible: false, title: '' });
    const [codigoCarregado, setCodigoCarregado] = useState(0);
    const [tipoConta, setTipoConta] = useState('');

    //Banco
    const [saldoAnterior, setSaldoAnterior] = useState(0);

    //TABELA
    const [colunasTable, setColunasTable] = React.useState([]);
    const [linhas, setLinhas] = React.useState([]);
    const [countAll, setCountAll] = React.useState(0);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const buscaDados = async () => {
        try {
            MostraTelaEspera()
            /*DADOS TABELA*/
            let complementoUrl = '';

            if (page > 0) {
                complementoUrl = `${complementoUrl}&offset=${page * rowsPerPage}`;
            }

            if (orderBy) {
                complementoUrl = `${complementoUrl}&order=${orderBy}`;
                if (order) {
                    complementoUrl = `${complementoUrl}&direction=${order}`;
                }
            }

            let url = `${rotafechada()}lancamento_banco/movimentacaocartao?banco_id=${props.banco_id}&data1=${new Date(filtro.dataInicialFormatada)}&data2=${new Date(filtro.dataFinalFormatada)}`
            //console.log('URL', url)
            const retornoDetalheParcela = await api.get(url);
            const { data, countAll: countAllretorno, dataSaldo } = retornoDetalheParcela.data;
            const dadostemp = [];

            //console.log("Data movimentação banco:", data)
            //console.log("dataSaldo:", dataSaldo)
            setValorFatura(dataSaldo.valorFatura)
            setSaldoAnterior(dataSaldo.saldoAnterior);
            setFaturaPaga(dataSaldo.faturaPaga);
            const colunasTabletemp = [
                // {
                //     id: 'id',
                //     numeric: false,
                //     disablePadding: true,
                //     label: 'id',
                //     disableOrder: true
                // },
                // {
                //     id: 'transferencia_id',
                //     numeric: false,
                //     disablePadding: true,
                //     label: 'transferencia_id',
                //     disableOrder: true
                // },
                {
                    id: 'data',
                    numeric: false,
                    disablePadding: true,
                    label: 'Pagamento',
                    disableOrder: true
                },
                {
                    id: 'vencimento',
                    numeric: false,
                    disablePadding: true,
                    label: 'Vencimento',
                    disableOrder: true
                },
                {
                    id: 'descricao',
                    numeric: false,
                    disablePadding: true,
                    label: 'Descrição',
                    disableOrder: true
                },
                {
                    id: 'simbolo',
                    numeric: false,
                    disablePadding: true,
                    label: 'Situação',
                    disableOrder: true
                },
                {
                    id: 'valor',
                    numeric: false,
                    disablePadding: true,
                    label: '',
                    disableOrder: true
                },
            ]
            data.map((item, index) => { //dados da tabela
                if(item.tipo == 'PAGAMENTO FATURA'){
                    setIdFatura(item.transferencia_id)
                }
                dadostemp.push({
                    id: item.id,
                    transferencia_id: item.transferencia_id,
                    data: formatDate(new Date(item.data), 'DD/MM/YYYY'),
                    vencimento: item.vencimento ? formatDate(new Date(item.vencimento), 'DD/MM/YYYY') : null,
                    descricao: item.observacao === 'Transferencia' ?
                        <Box>
                            <MDBox width='100%' display='flex' justifyContent="flex-start" flexDirection='row'>
                                <MDTypography onClick={() => { handleClickDetalheTransferencia(item.transferencia_id) }} sx={{ cursor: "pointer" }} variant="body2" textTransform="capitalize">{`${item?.tipo == 'PAGAMENTO FATURA' ? 'Pagamento de fatura' : 'Transferência'} - ${item.transferencia}`}</MDTypography>
                            </MDBox>
                            <MDBox width='100%' display='flex' justifyContent="flex-start" flexDirection='row'>
                                <MDTypography onClick={() => { handleClickDetalheTransferencia(item.transferencia_id) }} sx={{ cursor: "pointer" }} variant="caption" textTransform="capitalize">{<><strong>Descrição:</strong> {item.historico}</>}</MDTypography>
                            </MDBox>
                        </Box>
                        : item.status == 'pago' ?
                            <MDTypography onClick={() => { handleClickDetalheParcela(item.parcela_id, item.valor) }} sx={{ cursor: "pointer" }} variant="body2" textTransform="capitalize">{`${item.nome} - ${item.razao_social}`}</MDTypography> :
                            <MDTypography onClick={() => { handleClickLancar(item.parcela_id, item.valor) }} sx={{ cursor: "pointer" }} variant="body2" textTransform="capitalize">{`${item.nome} - ${item.razao_social}`}</MDTypography>,
                    //simbolo: item.observacao === 'Transferencia' ? <SwapVertIcon fontSize="medium" color={'info'} /> : item.valor > 0 ? <KeyboardDoubleArrowUpIcon fontSize="medium" color={'success'} /> : <KeyboardDoubleArrowDownIcon fontSize="medium" color={'error'} />,
                    simbolo: item.observacao === 'Transferencia' ? <SwapVertIcon fontSize="medium" color={'info'} /> : item.status == 'pago' ?
                        <MDBox width='100%'
                            justifyContent="flex-end" flexDirection='row'>
                            <MDBox
                                display="flex"
                                alignText={'center'}
                                width="100px"
                                justifyContent={'center'}
                                sx={{ background: '#bce2be' }}
                                borderRadius="sm"
                                p={0.5}
                            >
                                <MDTypography fontWeight="medium"
                                    variant="caption"
                                    textTransform="capitalize"
                                >
                                    pago
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                        :
                        <MDBox width='100%'
                            justifyContent="flex-end" flexDirection='row'>
                            <MDBox
                                display="flex"
                                alignText={'center'}
                                width="100px"
                                justifyContent={'center'}
                                sx={{ background: '#ffd59f' }}
                                borderRadius="sm"
                                p={0.5}
                            >
                                <MDTypography fontWeight="medium"
                                    variant="caption"
                                    textTransform="capitalize"
                                >
                                    aberto
                                </MDTypography>
                            </MDBox>
                        </MDBox>,
                    valor:
                        <>
                            <MDBox display="flex" justifyContent="flex-end" flexDirection='row'>
                                {item.valor > 0 ? <MDTypography variant="body2" pl={1} textTransform="capitalize" color={'success'}>{formataValor(item.valor)}</MDTypography> : <MDTypography variant="body2" pl={1} textTransform="capitalize" color={'error'}>{formataValor(parseFloat(item.valor))}</MDTypography>}
                            </MDBox>
                        </>

                })
            })

            setColunasTable([...colunasTabletemp]);
            setLinhas(dadostemp);
            setCountAll(countAllretorno);
            FechaTelaEspera()
            /*DADOS TABELA*/

        } catch (error) {
            FechaTelaEspera()
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenDetalhe(false)
    };

    const handleClickDetalheParcela = (id, valor) => {

        //console.log("handleClickDetalheParcela -- id e valor", id, valor)
        if (id !== 0) {
            setCodigoCarregado(id);
            setTipoConta(valor > 0 ? 'R' : 'D')
            setDetalheParcelaOpen({ ...detalheParcelaOpen, visible: true, nome: 'Detalhes da parcela ' });
        }

    }

    const handleExcluir = async () => {
        setDialogoOpen({ ...dialogoOpen, visible: false });

        try {
            await api.delete(`${rotafechada()}parcelas_conta/estornarfatura/${dialogoOpen.id || 0}`);
            MensagemAviso('Excluído com sucesso!');
            setFaturaPaga(false);
            setDialogoOpen({ ...dialogoOpen, visible: false })
            setRecarregaTabela(!recarregaTabela);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleClickEstornarFatura = (id) => {
        //rota de validação - revisar depois
        // if (!(await validaRotaPermissao('excluir') === true)) {
        //     return;
        // }
        setDialogoOpen({ ...dialogoOpen, visible: true, id });
    }

    const handleClickLancar = (id, valor) => {
        //console.log("handleClickLancar -- id e valor", id, valor)
        if (id !== 0) {
            setCodigoCarregado(id);
            setTipoConta(valor > 0 ? 'R' : 'D')
            setDetalheLancar({ ...detalheLancar, visible: true, nome: `Informar ${valor > 0 ? 'pagamento' : 'recebimento'}` });
        }

    }

    const handleClickDetalheTransferencia = (id) => {

        if (id !== 0) {
            setCodigoCarregado(id);
            setDetalheTransferenciaOpen({ ...detalheTransferenciaOpen, visible: true, title: 'Detalhes da transferência ' });
        }

    }

    const handleClickProximo = () => {

        let filtroTemp = filtro

        setFiltro(
            {
                labelOriginal: endOfMonth(addMonths(filtroTemp.dataInicialOriginal, 1)),
                labelFormatada: format(endOfMonth(addMonths(filtroTemp.dataInicialOriginal, 1)), "MMMM 'de' yyyy", { locale: ptBR }),

                dataInicialOriginal: addMonths(filtroTemp.dataInicialOriginal, 1),
                dataInicialFormatada: format(addMonths(filtroTemp.dataInicialOriginal, 1), "yyyy-MM-dd 00:00:00", { locale: ptBR }),

                dataFinalOriginal: endOfMonth(addMonths(filtroTemp.dataFinalOriginal, 1)),
                dataFinalFormatada: format(endOfMonth(addMonths(filtroTemp.dataFinalOriginal, 1)), "yyyy-MM-dd 23:59:59", { locale: ptBR }),

                descricaoFatura: `Pagamento fatura ${format(endOfMonth(addMonths(filtroTemp.dataInicialOriginal, 1)), "MMMM 'de' yyyy", { locale: ptBR })}`
            }
        )
    }

    const handleClickAnterior = () => {

        let filtroTemp = filtro

        setFiltro(
            {
                labelOriginal: endOfMonth(subMonths(filtroTemp.dataInicialOriginal, 1)),
                labelFormatada: format(endOfMonth(subMonths(filtroTemp.dataInicialOriginal, 1)), "MMMM 'de' yyyy", { locale: ptBR }),

                dataInicialOriginal: subMonths(filtroTemp.dataInicialOriginal, 1),
                dataInicialFormatada: format(subMonths(filtroTemp.dataInicialOriginal, 1), "yyyy-MM-dd 00:00:00", { locale: ptBR }),

                dataFinalOriginal: endOfMonth(subMonths(filtroTemp.dataFinalOriginal, 1)),
                dataFinalFormatada: format(endOfMonth(subMonths(filtroTemp.dataFinalOriginal, 1)), "yyyy-MM-dd 23:59:59", { locale: ptBR }),

                descricaoFatura: `Pagamento fatura ${format(endOfMonth(subMonths(filtroTemp.dataInicialOriginal, 1)), "MMMM 'de' yyyy", { locale: ptBR })}`

            }
        )

    }

    const limpaImputs = () => {
        setFiltro(
            {
                labelOriginal: endOfMonth(new Date()),
                labelFormatada: format(endOfMonth(new Date()), "MMMM 'de' yyyy", { locale: ptBR }),
    
                dataInicialOriginal: startOfMonth(new Date()),
                dataInicialFormatada: (format(startOfMonth(new Date()), "yyyy-MM-dd 00:00:00", { locale: ptBR })),
    
                dataFinalOriginal: endOfMonth(new Date()),
                dataFinalFormatada: (format(endOfMonth(new Date()), "yyyy-MM-dd 23:59:59", { locale: ptBR })),
    
                descricaoFatura: `Pagamento fatura ${format(endOfMonth(new Date()), "MMMM 'de' yyyy", { locale: ptBR })}`
            }
        );
    }

    useEffect(() => {
        if (open == true) {
            buscaDados();
        }
    }, [open, recarregaTabela, filtro])

    return (
        <>

            <DialogoConfirmaValidacao
                open={dialogoOpen.visible}
                title={'Confirma Estorno'}
                color={'error'}
                message={'Certeza que deseja estornar? '}
                validacao={'Concordo com a estorno desta fatura.'}
                handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
                handleConfirm={handleExcluir}
            />

            <Menu //Menu adicionar

                anchorEl={anchorEl}
                open={openDetalhe}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem //Nova Receita
                    dense={true}
                    //disabled={situacaoParcela == 'PAGO' ? true : false}
                    onClick={() => {
                        setOpenDetalhe(false)
                        handleClickReceita()
                    }}>
                    Nova Receita
                </MenuItem>

                <MenuItem //Nova Despesa
                    dense={true}
                    //disabled={situacaoParcela == 'PAGO' ? true : false}
                    onClick={() => {
                        setOpenDetalhe(false)
                        handleClickDespesa()
                    }}>
                    Nova Despesa
                </MenuItem>

                <MenuItem //Pagar Estornar Fatura
                    dense={true}
                    disabled={filtro.dataInicialOriginal > diaAtual || valorFatura >= 0 ? true : false}
                    onClick={() => {
                        console.log("validação", valorFatura)
                        setOpenDetalhe(false)
                        faturaPaga == true ? handleClickEstornarFatura(idFatura) : handleClickTransferencia(props.banco_id, Math.abs(valorFatura), filtro.dataInicialFormatada, filtro.dataFinalFormatada, filtro.descricaoFatura)
                        //setRecarregaTabela(!recarregaTabela)
                    }}>
                    {faturaPaga == true ? 'Estornar fatura' : 'Pagar fatura'}
                </MenuItem>

            </Menu>


            {detalheParcelaOpen.visible == true &&
                <DetalheParcela
                    open={detalheParcelaOpen.visible}
                    title={detalheParcelaOpen.nome}
                    message={'Mensagem'}
                    tipo={tipoConta}
                    setCodigoAbrir={setCodigoCarregado}
                    recarregaTabela={recarregaTabela}
                    setRecarregaTabela={setRecarregaTabela}
                    codigoAbrir={codigoCarregado}
                    handleCancel={() => {
                        setDetalheParcelaOpen({ ...detalheParcelaOpen, visible: false, nome: '' })
                        //setAba(0);
                    }}
                />
            }
            {detalheTransferenciaOpen.visible == true &&
                <DetalheTransferencia
                    open={detalheTransferenciaOpen.visible}
                    title={detalheTransferenciaOpen.title}
                    message={'Mensagem'}
                    setCodigoAbrir={setCodigoCarregado}
                    codigoAbrir={codigoCarregado}
                    recarregaTabela={recarregaTabela}
                    setRecarregaTabela={setRecarregaTabela}
                    handleCancel={() => {
                        setDetalheTransferenciaOpen({ ...detalheTransferenciaOpen, visible: false, nome: '' })
                        //setAba(0);
                    }}
                />
            }

            {detalheLancar.visible == true &&
                <Lancar
                    open={detalheLancar.visible}
                    title={detalheLancar.nome}
                    message={'Mensagem'}
                    tipo={tipoConta}
                    setCodigoAbrir={setCodigoCarregado}
                    codigoAbrir={codigoCarregado}
                    setRecarregaTabela={setRecarregaTabela}
                    recarregaTabela={recarregaTabela}
                    handleCancel={() => {
                        setDetalheLancar({ ...detalheLancar, visible: false, nome: '' })
                        //setAba(0);
                    }}
                />
            }


            <Dialog
                open={open}
                onClose={() => {
                    limpaImputs()
                    handleCancel()
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullScreen
            >
                <DialogTitle id="alert-dialog-title" sx={{ padding: '5px' }}>{/*HEADER*/}
                    <MDBox width="100%">
                        <MDBox display="flex" justifyContent="space-between">
                            <MDBox display="flex" justifyContent="space-start" flexDirection='column'>
                                <MDTypography fontWeight='bold' ml={5} mt={1} variant='h4'>{'Movimentações do Cartão de Crédito'}</MDTypography>
                            </MDBox>

                            <Tooltip title="Fechar (Esc)">
                                <MDButton size="large" sx={{ padding: 0 }} onClick={() => {
                                    limpaImputs()
                                    handleCancel()
                                }}>
                                    <Icon>close</Icon>
                                </MDButton>

                            </Tooltip>
                        </MDBox>
                    </MDBox>
                </DialogTitle>


                <DialogContent dividers sx={{ backgroundColor: "#f1f4f9", borderColor: '#7bb5e4' }}>
                    <DialogContentText id="alert-dialog-description">

                        <MDBox ml={2} mr={2} pb={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}>{/*Cabeçalho*/}
                            <Grid container spacing={3} mt={1} pl={2} pr={2}>
                                <Grid item xs={12} sm={12}> {/* TITULO*/}
                                    <MDBox width='100%' display='flex' alignItems='space-between' flexDirection='row'>
                                        <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='row'>{/* Nome do caixa e saldo */}
                                            <img src={Bank} alt="Banco"></img>
                                            {/* <img src={title.length > 0 ?
                                            `https://app.contaazul.com/versions/current/assets/images/bank/50x50/${title}.svg`
                                            : `https://app.contaazul.com/versions/current/assets/images/bank/50x50/outro-banco.svg`}
                                            alt="Logo Banco" /> */}
                                            <MDBox pl={3} width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                                <MDTypography variant='subtitle1' fontWeight="bold" textTransform="capitalize" >{title}</MDTypography>
                                            </MDBox>
                                        </MDBox>
                                        <MDBox width='100%' display='flex' alignItems='center' flexDirection='column'> {/* Período */}
                                            <MDTypography variant='subtitle2' textTransform="capitalize" fontWeight='bold'>{`Período`}</MDTypography>
                                            <Box justifyContent='center' width='100%' display='flex' alignItems='center' flexDirection='row'>

                                                <Tooltip title="Anterior" placement="top" disableInteractive>
                                                    <MDButton
                                                        onClick={handleClickAnterior}
                                                        variant="contained"
                                                        color={'info'}
                                                        sx={{ borderBottomLeftRadius: '3px', borderTopLeftRadius: '3px', borderBottomRightRadius: '0px', borderTopRightRadius: '0px', borderColor: "#c9d3dd" }}
                                                    >
                                                        <ArrowBackIosIcon />
                                                    </MDButton>
                                                </Tooltip>
                                                <Box pl={0.2} pr={0.2}>
                                                    <MDButton
                                                        variant="contained"
                                                        color={'info'}
                                                        sx={{ borderRadius: '0px', borderColor: 'white', cursor: "not-allowed" }}
                                                    >
                                                        {filtro.labelFormatada}
                                                    </MDButton>
                                                </Box>
                                                <Tooltip title="Próximo" placement="top" disableInteractive>
                                                    <MDButton
                                                        onClick={handleClickProximo}
                                                        variant="contained"
                                                        color={'info'}
                                                        sx={{ borderBottomRightRadius: '3px', borderTopRightRadius: '3px', borderBottomLeftRadius: '0px', borderTopLeftRadius: '0px' }}>
                                                        <ArrowForwardIosIcon />
                                                    </MDButton>
                                                </Tooltip>
                                            </Box>
                                        </MDBox>
                                        <MDBox width='100%' display='flex' alignItems='flex-end' flexDirection='column' pb={1}> {/* Adicionar */}
                                            <MDTypography variant='subtitle2' > </MDTypography>
                                            <MDButton
                                                variant="gradient"
                                                color={'info'}
                                                onClick={(e) => {
                                                    setAnchorEl(e.currentTarget);
                                                    setOpenDetalhe(true)
                                                }}
                                                sx={{ borderRadius: '3px' }}
                                                endIcon={<KeyboardArrowDownIcon />}
                                            //disableElevation
                                            >
                                                opções
                                            </MDButton>
                                        </MDBox>
                                    </MDBox>

                                    <Divider
                                        sx={{ bgcolor: (theme) => theme.palette.divider }}
                                        style={{
                                            marginTop: 5,
                                            marginBottom: 10,
                                            border: "none",
                                            height: 2,
                                            margin: 0,
                                        }}
                                    />
                                </Grid>
                            </Grid>

                        </MDBox>

                        {/* <MDBox ml={2} mr={2} pb={2} sx={{ borderRadius: '3px' }}>Meio */}
                        <Grid container spacing={2} pt={1} >
                            <Grid item xs={12} sm={3.9} ml={2}> {/* TITULO*/}
                                <MDBox pl={2} pt={0.8} pb={0.8} bgColor={'#fff'} width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                    <MDTypography variant='subtitle2' textTransform="capitalize" ><strong>Saldo Anterior: </strong> {`${formataValor(saldoAnterior)}`}</MDTypography>
                                    <MDTypography variant='subtitle2' textTransform="capitalize" > </MDTypography>
                                    <MDTypography variant='subtitle2' textTransform="capitalize" ><strong>Fechamento:</strong> {`${dia_fechamento} ${filtro.labelFormatada}`}</MDTypography>
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} sm={3.9}> {/* TITULO*/}
                                <MDBox p={2} bgColor={'#fff'} width='100%' display='flex' alignItems='center' flexDirection='column'>
                                    <MDTypography variant='subtitle2' textTransform="capitalize" fontWeight="bold">Vencimento</MDTypography>
                                    <MDTypography variant='subtitle1' textTransform="capitalize" fontWeight="bold">{`${dia_vencimento} ${filtro.labelFormatada}`}</MDTypography>
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} sm={3.9}> {/* TITULO*/}
                                <MDBox p={2} bgColor={'#fff'} width='100%' display='flex' alignItems='center' flexDirection='column'>
                                    <MDTypography variant='subtitle2' textTransform="capitalize" fontWeight="bold">Valor da fatura</MDTypography>
                                    <MDTypography variant='subtitle1' textTransform="capitalize" fontWeight="bold">{`${formataValor(valorFatura)}`}</MDTypography>
                                </MDBox>
                            </Grid>
                        </Grid>

                        {/* </MDBox> */}

                        <MDBox ml={2} mr={2} pb={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/* CONTA */}
                            <Grid container spacing={3} mt={1} pl={2} pr={2}>
                                <Grid item xs={12} sm={12}>  {/* TABELA*/}
                                    <TabelaPadrao
                                        colunas={colunasTable}
                                        linhas={linhas}
                                        countAll={countAll}
                                        page={page}
                                        setPage={setPage}
                                        rowsPerPage={rowsPerPage}
                                        setRowsPerPage={setRowsPerPage}
                                        order={order}
                                        setOrder={setOrder}
                                        orderBy={orderBy}
                                        setOrderBy={setOrderBy}
                                    />
                                </Grid>

                            </Grid>
                        </MDBox>

                    </DialogContentText>
                </DialogContent>

                <DialogActions sx={{ padding: '7px', margin: 0.5 }}> {/*FOOTER*/}
                    <MDBox width="100%">
                        <MDBox width="100%" display="flex" justifyContent="space-between">
                            <MDButton onClick={() => {
                                limpaImputs()
                                handleCancel()
                            }} variant="gradient" type="button" color="secondary">
                                voltar
                            </MDButton>
                            {/* <MDButton
                                onClick={handleClickGravar}
                                variant="gradient"
                                type="submit"
                                //type="button" 
                                color="info">
                                {'salvar'}
                            </MDButton> */}
                        </MDBox>
                    </MDBox>
                </DialogActions>
            </Dialog>
        </>
    );
}