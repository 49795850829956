import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import { useContext } from 'react';
import MessagesContext from 'hooks/MessagesContext';
import { useEffect } from 'react';
import { useState } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { copiaProfundaObjetos } from 'commons/utils';
import { Box } from '@mui/system';


export default function FiltroListaMateriaisImpressao(props) {

  const { open, setOpen, handleOk } = props;
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso } = messageContext;

  const [tiposProdutos, setTiposProdutos] = useState([]);

  const [mostraImagem, setMostraImagem] = useState(false);
  const [mostraTipo, setMostraTipo] = useState(true);
  const [mostraReferencia, setMostraReferencia] = useState(true);
  const [mostraNomeProduto, setMostraNomeProduto] = useState(true);
  const [mostraCor, setMostraCor] = useState(true);
  //const [mostraMedida, setMostraMedida] = useState(true);
  const [mostraPreco, setMostraPreco] = useState(false);

  const [agrupaProduto, setAgrupaProduto] = useState(true);

  const setChecked = (id, checked) => {

    const temp = copiaProfundaObjetos(tiposProdutos);

    for (let i = 0; i < temp.length; i++) {
      if (parseInt(temp[i].id, 10) === parseInt(id, 10)) {
        temp[i].checked = checked;
      }
    }

    setTiposProdutos(temp);
  }

  const buscaTiposProdutos = async () => {

    let url = '';
    let retorno;

    try {
      url = `${rotafechada()}tipos_produtos?limit=-1`;
      retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      const temp = [];

      data.map((item) => {
        temp.push({ id: item.id, nome: item.nome, checked: true })
      })

      setTiposProdutos(temp);

    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickOk = () => {

    if (!handleOk)
      return

    //envio somente os tipos selecionados
    const tipos = tiposProdutos.filter((item) => item.checked);

    handleOk({
      tipos,
      mostraImagem,
      mostraTipo,
      mostraReferencia,
      mostraNomeProduto,
      mostraCor,
      //mostraMedida,
      mostraPreco,
      agrupaProduto,
    }
    );
  }

  useEffect(() => {

    if (open) {
      buscaTiposProdutos();
    }

  }, [open])


  return (
    <>
      <Dialog
        maxWidth="xl"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>{'Filtros do Relatório'}</DialogTitle>

        <DialogContent dividers>
          <Typography variant='h6'>Escolha os tipos:</Typography>
          <Box display='flex' mb={2}>
            {
              tiposProdutos?.map((item) =>
                <FormControlLabel ml={2} control={<Checkbox checked={item.checked} onChange={(e) => setChecked(item.id, e.target.checked)} />} label={item.nome} />
              )
            }
          </Box>

          <Typography variant='h6'>Colunas</Typography>

          <Box display='flex'>
            <FormControlLabel ml={2} control={<Checkbox checked={mostraImagem} onChange={(e) => setMostraImagem(e.target.checked)} />} label='Imagem' />
            <FormControlLabel ml={2} control={<Checkbox checked={mostraTipo} onChange={(e) => setMostraTipo(e.target.checked)} />} label='Tipo' />
            <FormControlLabel ml={2} control={<Checkbox checked={mostraReferencia} onChange={(e) => setMostraReferencia(e.target.checked)} />} label='Referencia' />
            <FormControlLabel ml={2} control={<Checkbox checked={mostraNomeProduto} onChange={(e) => setMostraNomeProduto(e.target.checked)} />} label='Nome Material' />
            <FormControlLabel ml={2} control={<Checkbox checked={mostraCor} onChange={(e) => setMostraCor(e.target.checked)} />} label='Cor' />
            {/* <FormControlLabel ml={2} control={<Checkbox checked={mostraMedida} onChange={(e) => setMostraMedida(e.target.checked)} />} label='Qtde/Medida' /> */}
            <FormControlLabel ml={2} control={<Checkbox checked={mostraPreco} onChange={(e) => setMostraPreco(e.target.checked)} />} label='Preço' />
          </Box>

          <Typography variant='h6'>Outras opções:</Typography>
          <Box display='flex'>
            <FormControlLabel ml={2} control={<Checkbox checked={agrupaProduto} onChange={(e) => setAgrupaProduto(e.target.checked)} />} label='Agrupa Produtos com mesma medida' />
          </Box>

        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            Cancelar
          </Button>
          <Button onClick={handleClickOk}>Ok</Button>
        </DialogActions>
      </Dialog>

    </>)
}