import React, { useContext, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { Box, Button, Collapse, Grid, IconButton, TextField, Typography } from '@mui/material';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';

import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import MessagesContext from 'hooks/MessagesContext';
import MDButton from 'components/MDButton';
// import AuthContext from 'hooks/AuthContext';
import DialogoConfirma from 'componentes/DialogoConfirma';
import TabelaVendaProjetos from './tabela';
import PesquisaProjetos from './pesquisaprojetos';
import DetalhamentoProjeto from './detalhamentoprojeto';
import { Link } from 'react-router-dom';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import MostraImagem from 'componentes/MostraImagem';
import DialogoOpcoes from 'componentes/DialogoOpcoes';
import ReplicacaoProjetoVenda from './replicacao';



const CabecalhoProjetos = (props) => {

    const { control, setValue, getValues, vendaAtual, abrirRegistro,
        IniciaEtapas, recarregaVenda, codigoProjetoAtual,
        setCodigoProjetoAtual, recarregaTabela, setRecarregaTabela, urlImagem, setUrlImagem, verificaVendaConcluida } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
    // const authContext = useContext(AuthContext);
    // const { getSessao } = authContext;
    //const sessaolocal = getSessao();
    const [dialogoExclusaoOpen, setDialogoExclusaoOpen] = useState({ visible: false, id: null, message: '' });
    const opcaoDefault = [{ id: -1, value: -1, label: '<Selecione...>' }];
    const [confPesquisaDinamica, setConfPesquisaDinamica] = useState({});

    const [opcoesGrupoProjeto, setOpcoesGrupoProjeto] = useState([{ value: -1, label: '<Todos>' }])
    const [openCollapseDetalhamento, setOpenCollapseDetalhamento] = useState(false);
    const [openZoomImg, setOpenZoomImg] = useState(false);

    const [openReplicacao, setOpenReplicacao] = useState(false);

    const [dialogoPadrao, setDialogoPadrao] = useState({ open: false, message: '' })

    const [opcoes, setOpcoes] = useState({
        open: false,
        items: [{}]
    });

    const ObjModeloVendasProjetos = {
        id: { type: 'text' },
        projeto_id: { type: 'text' },
        ambiente_instalacao: { type: 'text' },
        valor_total: { type: 'text' },
        valor_desconto: { type: 'text' },
        valor_final: { type: 'text' },
    }

    const validaRotaPermissao = async (operacao = '', silencioso = false) => {
        if ((operacao !== 'inserir') &&
            (operacao !== 'editar') &&
            (operacao !== 'excluir') &&
            (operacao !== 'listar')) {
            MensagemErro('Escolha a operação que deseja validar a permissão!');
            return false;
        }

        //validando a permissao
        try {
            await api.get(`${rotafechada()}venda_projetos/permissao/${operacao}`);
            return true;
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;

            if (silencioso === false) {
                MensagemErro(`Erro: ${msg}`);
            }
            return false;
        }
    }

    const novoRegistro = () => {
        //limpo os campos
        Object.keys(ObjModeloVendasProjetos).forEach((nome) => {
            if (ObjModeloVendasProjetos[nome].type === 'autocomplete') {
                setValue(`projetos.${nome}`, opcaoDefault[0])
            }
            else
                if (ObjModeloVendasProjetos[nome].type === 'checkbox') {
                    setValue(`projetos.${nome}`, false)
                }
                else
                    if (ObjModeloVendasProjetos[nome].type === 'text') {
                        setValue(`projetos.${nome}`, '')
                    }
        })
        setValue('projetos.nome_projeto_id', '');
        setCodigoProjetoAtual('');
        setUrlImagem('');
    }

    const excluirRegistro = async () => {

        setDialogoExclusaoOpen({ ...dialogoExclusaoOpen, visible: false });
        try {
            await api.delete(`${rotafechada()}venda_projetos/full/${dialogoExclusaoOpen.id || 0}`);
            MensagemAviso('Excluído com sucesso!');
            novoRegistro();
            setRecarregaTabela(!recarregaTabela);
            recarregaVenda();
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const opcoesProjetos = async () => {
        const items = [
            { value: 0, label: 'Replicar o Projeto' },
            { value: 1, label: 'Excluir os replicados' },
        ];
        setOpcoes({ ...opcoes, open: true, tipo: 'opcoes', items });

    }

    const validaAberturaReplicacao = async () => {

        const id = parseInt(getId, 10);

        if (id > 0) {
            //recálculo
            try {
                MostraTelaEspera();
                let url = `${rotafechada()}venda_projetos/abrir/${id}`;
                const retorno = await api.get(encodeURI(url));
                const { data } = retorno.data;
                FechaTelaEspera();

                if (parseInt(data.venda_projeto_id_pai, 10) > 0) {
                    setDialogoPadrao({
                        open: true,
                        message: `O projeto ${id} é fruto de uma replicação! Se você replicá-lo ele será desvinculado. Certeza que deseja continuar? `,
                        handleConfirm: () => {
                            setDialogoPadrao({ open: false });
                            setOpenReplicacao(true);
                        }
                    });
                    return;
                }

                setOpenReplicacao(true);
            }
            catch (error) {
                FechaTelaEspera();
                const msg = error.response?.data?.msg || error;
                MensagemErro(`Erro: ${msg}`);
                return;
            }
        }
    }

    const onCloseDialogoOpcoes = async (value) => {

        //fechando o dialogo
        setOpcoes({ ...opcoes, open: false, tipo: '' });

        switch (parseInt(value, 10)) {
            case 0:
                validaAberturaReplicacao();
                break;
            case 1:
                excluirProjetoReplicados();
                break;
            default:
                break;
        }
    }

    const excluirProjetoReplicados = async () => {

        try {
            await api.delete(`${rotafechada()}venda_projetos/replicados/${getId || 0}`);
            MensagemAviso('Replicados excluídos com sucesso!');
            novoRegistro();
            setRecarregaTabela(!recarregaTabela);
            recarregaVenda();
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
        }
    };

    const handleClickExcluir = async (id) => {

        const erroVendaConcluida = await verificaVendaConcluida(true, ' Não é permitido excluir projetos');
        if (erroVendaConcluida) {
            return;
        }

        if (!(await validaRotaPermissao('excluir') === true)) {
            return;
        }

        //verificando se o projeto nao tem "filhos"

        try {
            MostraTelaEspera();
            let url = `${rotafechada()}venda_projetos/abrir/${id}`;
            const retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            FechaTelaEspera();

            if (parseInt(data.quantidadereplicados, 10) > 0) {
                setDialogoExclusaoOpen({
                    ...dialogoExclusaoOpen, visible: true, id,
                    message: `O projeto ${id} possui ${data.quantidadereplicados} projetos replicados a partir dele. Se você excluir irá excluir todos os projetos replicados. Certeza que deseja excluí-lo? `
                });
            }
            else setDialogoExclusaoOpen({
                ...dialogoExclusaoOpen, visible: true, id,
                message: `Certeza que deseja excluir o projeto ${id}?`
            });

        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
            return;
        }
    }

    const buscaGrupoProjetos = async () => {
        try {
            let url = `${rotafechada()}grupos_projetos?limit=-1`;
            const retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;

            const gruposTemp = [];

            gruposTemp.push({ value: -1, label: '<Todos>' });

            data?.map((item) => {
                gruposTemp.push({ value: item.id, label: item.nome });
            })

            setOpcoesGrupoProjeto(gruposTemp);

        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleClickPesquisaProjeto = async () => {
        await buscaGrupoProjetos();
        setConfPesquisaDinamica({ ...confPesquisaDinamica, open: true });
    }

    const handleFuncaoSelecaoPesquisaDinamica = (codigo, datasetPesquisa) => {
        /* Essa funcao é chamada pelo componente de pesquisa dinamica quando um registro é escolhido
        ele retorna os campos que foram configurados*/
        setConfPesquisaDinamica({ ...confPesquisaDinamica, open: false });
        setValue('projetos.projeto_id', datasetPesquisa.id);
        setValue('projetos.nome_projeto_id', datasetPesquisa.nome);
        setUrlImagem(datasetPesquisa.imagem ? datasetPesquisa.imagem : '');
    }

    const handleBlurProjeto = async () => {
        if (!(parseInt(getValues('projetos.projeto_id'), 10) >= 0)) {
            setValue('projetos.nome_projeto_id', '');
            return;
        }
        try {
            let url = `${rotafechada()}projetos?retornaimagem=S&id=${getValues('projetos.projeto_id')}`;
            const retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            if ((data) && (data?.length > 0)) {
                setValue('projetos.projeto_id', data[0].id);
                setValue('projetos.nome_projeto_id', data[0].nome);
            } else {
                setValue('projetos.projeto_id', '');
                setValue('projetos.nome_projeto_id', '');
            }

            setUrlImagem(data[0]?.imagem ? data[0].imagem : '');
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleClickAbrir = async (codigo = '') => {
        setOpenCollapseDetalhamento(false);
        await abrirRegistro(codigo);
        const imagem = getValues('projetos.imagem');
        setUrlImagem(imagem ? imagem : '');

    }

    const getProjetoId = useWatch({
        control,
        name: "projetos.projeto_id",
    });

    const getNomeProjeto = useWatch({
        control,
        name: "projetos.nome_projeto_id",
    });

    const getId = useWatch({
        control,
        name: "projetos.id",
    });

    const getConta_id = useWatch({
        control,
        name: "conta_id",
    });

    const buscaValorProjeto = async () => {
        const venda_projeto_id = parseInt(getValues('projetos.id'), 10);
        try {
            const url = `${rotafechada()}venda_projetos`;
            const retorno = await api.get(`${url}?id=${venda_projeto_id}`);
            //projeto
            setValue('projetos.valor_total', retorno.data.data[0].valor_total);
            setValue('projetos.valor_desconto', retorno.data.data[0].valor_desconto);
            setValue('projetos.valor_final', retorno.data.data[0].valor_final);
            recarregaVenda();
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
        }
    }

    const handleBlurValorDesconto = async () => {
        let valor_desconto = `${getValues('projetos.valor_desconto')}`.replace(',', '.');
        const venda_projeto_id = parseInt(getValues('projetos.id'), 10);
        if (!(venda_projeto_id > 0)) {
            setValue('projetos.valor_desconto', '0');
            return;
        }
        try {
            valor_desconto = parseFloat(valor_desconto)
        }
        catch (error) {
            MensagemErro(`Desconto do projeto deve ser um numero`);
            buscaValorProjeto();
            return;
        }

        try {
            const url = `${rotafechada()}venda_projetos`;
            const retorno = await api.put(`${url}/${venda_projeto_id}`, { valor_desconto, venda_projeto_id_pai: null });
            //projeto
            setValue('projetos.valor_total', retorno.data.data.valor_total);
            setValue('projetos.valor_desconto', retorno.data.data.valor_desconto);
            setValue('projetos.valor_final', retorno.data.data.valor_final);
            recarregaVenda();
            setRecarregaTabela(!recarregaTabela);
            MensagemAviso('Desconto no Projeto alterado com Sucesso!')
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
            //se deu erro preciso voltar os valores que estao no BD
            buscaValorProjeto();
        }
    }

    const handleBlurAmbienteInstalacao = async () => {
        const ambiente_instalacao = `${getValues('projetos.ambiente_instalacao')}`.replace(',', '.');
        const venda_projeto_id = parseInt(getValues('projetos.id'), 10);
        if (!(venda_projeto_id > 0)) {//se nao for edicao nao precisa gravar no onexit
            return;
        }
        try {
            const url = `${rotafechada()}venda_projetos`;
            const retorno = await api.put(`${url}/${venda_projeto_id}`, { ambiente_instalacao, venda_projeto_id_pai: null });
            setRecarregaTabela(!recarregaTabela);
            MensagemAviso('Ambiente atualizado com Sucesso!')
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
        }
    }

    const handleClickAbrirImg = async () => {
        setOpenZoomImg(true);
    }

    const encerraReplicacao = () => {
        //chamado apos concluir uma replicacao
        setOpenReplicacao(false);
        novoRegistro();
        setRecarregaTabela(!recarregaTabela);
        recarregaVenda();
    }

    const handleClickIniciaEtapas = async () => {

        const id = parseInt(getId, 10);

        if (id > 0) {
            //recálculo
            try {
                MostraTelaEspera();
                let url = `${rotafechada()}venda_projetos/abrir/${id}`;
                const retorno = await api.get(encodeURI(url));
                const { data } = retorno.data;
                FechaTelaEspera();

                if (parseInt(data.quantidadereplicados, 10) > 0) {
                    setDialogoPadrao({
                        open: true,
                        message: `O projeto ${id} possui ${data.quantidadereplicados} projetos replicados a partir dele. Se você recalculá-lo os filhos ficarão diferentes. Certeza que deseja continuar? `,
                        handleConfirm: IniciaEtapas

                    });

                    return;
                }
                else
                    if (parseInt(data.venda_projeto_id_pai, 10) > 0) {
                        setDialogoPadrao({
                            open: true,
                            message: `O projeto ${id} é fruto de uma replicação! Se você recalculá-lo ele será desvinculado. Certeza que deseja continuar? `,
                            handleConfirm: IniciaEtapas
                        });
                        return;
                    }
            }
            catch (error) {
                FechaTelaEspera();
                const msg = error.response?.data?.msg || error;
                MensagemErro(`Erro: ${msg}`);
                return;
            }
        }

        IniciaEtapas();
    }

    return (
        <>
            <MostraImagem
                open={openZoomImg}
                setOpen={setOpenZoomImg}
                img={`data:image/png[jpg];base64,${urlImagem}`}
                alt='Imagem do projeto'
            />
            <PesquisaProjetos
                open={confPesquisaDinamica.open || false}
                handleClose={
                    () => setConfPesquisaDinamica({ ...confPesquisaDinamica, open: false })
                }
                handleClick={handleFuncaoSelecaoPesquisaDinamica}
                opcoesGrupoProjeto={opcoesGrupoProjeto}
            />

            <DialogoConfirma // Esse é o dialogo para excluir um projeto
                open={dialogoExclusaoOpen.visible}
                title={'Confirmação'}
                color={'error'}
                message={dialogoExclusaoOpen.message || ''}
                handleCancel={() => setDialogoExclusaoOpen({ ...dialogoExclusaoOpen, visible: false })}
                handleConfirm={excluirRegistro}
            />

            <DialogoConfirma // Esse dialogo é para avisar a replicação de um projeto replicado 
                open={dialogoPadrao.open}
                title={'Confirmação'}
                message={dialogoPadrao.message || ''}
                handleCancel={() => setDialogoPadrao({ ...dialogoPadrao, open: false })}
                handleConfirm={dialogoPadrao.handleConfirm}
            />

            <DialogoOpcoes
                value={''}
                open={opcoes.open || false}
                onClose={onCloseDialogoOpcoes}
                options={opcoes.items || []}
            />

            <ReplicacaoProjetoVenda
                open={openReplicacao}
                setOpen={setOpenReplicacao}
                encerraReplicacao={encerraReplicacao}
                id={getId}
            />

            <Grid container spacing={1}>
                <Grid item xs={12} sm={9}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={2}>{/*id*/}
                            <Controller
                                name={`projetos.id`}
                                control={props.control}
                                defaultValue=''
                                render={({ field: { ref, ...props } }) =>
                                    <FormField
                                        autoComplete={false}
                                        type='text'
                                        label='Id'
                                        rows={1}
                                        multiline={false}
                                        required={false}
                                        disabled={true}
                                        inputProps={{
                                            disabled: true
                                        }}
                                        inputRef={ref}
                                        {...props}
                                    />}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>{/*valor_total*/}
                            <Controller
                                name={`projetos.valor_total`}
                                control={props.control}
                                defaultValue=''
                                render={({ field: { ref, ...props } }) =>
                                    <FormField

                                        autoComplete={false}
                                        type='text'
                                        label='Total R$'
                                        rows={1}
                                        multiline={false}
                                        required={false}
                                        disabled={true}
                                        inputRef={ref}
                                        InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                        inputProps={{
                                            disabled: true
                                        }}
                                        {...props}

                                    />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>{/*valor_desconto*/}
                            <Controller
                                name={`projetos.valor_desconto`}
                                control={props.control}
                                defaultValue=''
                                render={({ field: { ref, ...props } }) =>
                                    <FormField

                                        autoComplete={false}
                                        type='text'
                                        label='Desconto'
                                        rows={1}
                                        multiline={false}
                                        required={false}
                                        disabled={
                                            ((parseInt(getConta_id, 10) > 0)) ?
                                                true
                                                :
                                                parseInt(getId, 10) > 0 ? false : true
                                        }

                                        inputRef={ref}
                                        {...props}
                                        InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                        inputProps={{
                                            disabled: parseInt(getId, 10) > 0 ? false : true,
                                            onBlur: (e) => {
                                                e.preventDefault();
                                                handleBlurValorDesconto();
                                            },
                                            onChange: (e) => {
                                                if (e.target.value < 0) {
                                                    e.target.value = Math.abs(e.target.value);
                                                }
                                                props.onChange(e);
                                            }
                                        }}

                                    />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>{/*valor_final*/}
                            <Controller
                                name={'projetos.valor_final'}
                                control={props.control}
                                defaultValue=''
                                render={({ field: { ref, ...props } }) =>
                                    <FormField
                                        autoComplete={false}
                                        type='text'
                                        label='Valor Final R$'
                                        rows={1}
                                        multiline={false}
                                        required={false}
                                        InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                        disabled={true}
                                        inputRef={ref}
                                        inputProps={{
                                            disabled: true
                                        }}
                                        {...props}
                                    />}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>{/*projeto_id*/}
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={2}>{/*projeto_id*/}
                                    <Controller
                                        name={'projetos.projeto_id'}
                                        control={control}
                                        render={({ field: { ref, ...props } }) =>
                                            <FormField
                                                type='text'
                                                label='Projeto'
                                                rows={1}
                                                multiline={false}
                                                required={true}
                                                disabled={parseInt(getConta_id, 10) > 0 ? true : parseInt(getId, 10) > 0 ? true : false}

                                                {...props}
                                                inputProps={{
                                                    disabled: parseInt(getId, 10) > 0 ? true : false,
                                                    onKeyUp: (e) => {
                                                        e.preventDefault();
                                                        if (e.key === 'F9') {
                                                            handleClickPesquisaProjeto();
                                                        }
                                                    },
                                                    onBlur: (e) => {
                                                        e.preventDefault();
                                                        handleBlurProjeto();
                                                    },
                                                }}
                                                inputRef={ref}
                                                InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                            />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={1}>{/*botao do produto_id*/}
                                    <MDBox width="100%" height='100%' display="flex" justifyContent='center' alignItems='center' >
                                        <Button disabled={parseInt(getConta_id, 10) > 0 ? true : parseInt(getId, 10) > 0 ? true : false}>
                                            <SearchIcon sx={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    handleClickPesquisaProjeto();
                                                }}
                                            />
                                        </Button>
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} sm={9}>{/*label do produto_id*/}
                                    <MDBox width="100%" height='100%' display="flex" alignSelf='flex-start' alignItems='center' >
                                        <Typography variant='caption'>
                                            <Link to={`/projetos/projeto/${getProjetoId}`} style={{ textDecoration: "none", color: 'black' }}>{getNomeProjeto}</Link>
                                        </Typography>

                                    </MDBox>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12}>{/*ambiente_instalacao*/}
                            <Controller
                                name={`projetos.ambiente_instalacao`}
                                control={props.control}
                                defaultValue=''
                                render={({ field: { ref, ...props } }) =>
                                    <FormField
                                        fullWidth
                                        autoComplete={false}
                                        type='text'
                                        label='Ambiente de Instalação'
                                        rows={1}
                                        multiline={false}
                                        required={false}
                                        disabled={parseInt(getConta_id, 10) > 0 ? true : false}
                                        inputRef={ref}
                                        {...props}
                                        inputProps={{
                                            onBlur: (e) => {
                                                e.preventDefault();
                                                handleBlurAmbienteInstalacao();
                                            },
                                        }}

                                    />}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={3}>
                    <MDBox m={2} p={2} shadow="lg" borderRadius="lg" width="100%" display='flex' justifyContent='center'>
                        {
                            urlImagem !== '' ?
                                <img src={`data:image/png[jpg];base64,${urlImagem}`} alt="Imagem do Projeto" onClick={() => handleClickAbrirImg()} style={{ maxHeight: 200, maxWidth: 200, cursor: "zoom-in" }} />
                                :
                                false
                        }
                    </MDBox>
                    <MDBox display={'flex'} justifyContent={'center'}>
                        {
                            urlImagem != '' ?
                                <ZoomInIcon onClick={() => handleClickAbrirImg()} sx={{ ml: 2 }} color={'info'} fontSize='medium' />
                                :
                                false
                        }
                    </MDBox>
                </Grid>
            </Grid>

            <MDBox width="100%" display="flex" justifyContent="flex-end" mt={2}>
                <MDButton
                    disabled={((parseInt(getConta_id, 10) > 0)) ? true : false}


                    variant="gradient" color="info" onClick={handleClickIniciaEtapas} sx={{ ml: 2 }}>{parseInt(getId, 10) > 0 ? 'Recalcular' : 'Avançar'}</MDButton>
                {
                    parseInt(getId, 10) > 0 &&
                    <MDButton
                        disabled={parseInt(getConta_id, 10) > 0 ? true : false}
                        variant="gradient" color="dark" onClick={opcoesProjetos} sx={{ ml: 2 }}>Opções</MDButton>
                }
                <MDButton
                    disabled={parseInt(getConta_id, 10) > 0 ? true : false}
                    variant="gradient" color="info" onClick={novoRegistro} sx={{ ml: 2 }}>Novo</MDButton>
            </MDBox>

            {(parseInt(getId, 10) > 0) &&
                <>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpenCollapseDetalhamento(!openCollapseDetalhamento)}>
                        {openCollapseDetalhamento ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        <Typography variant='h6' mb={2}>Informações do Projeto</Typography>
                    </IconButton>
                    <Collapse in={openCollapseDetalhamento} timeout="auto" unmountOnExit>
                        <MDBox width="100%" display="flex" mt={2}>
                            <DetalhamentoProjeto
                                venda_projeto_id={codigoProjetoAtual}
                            />
                        </MDBox>
                    </Collapse>
                </>
            }

            <MDBox width="100%" display="flex" mt={2}>
                <TabelaVendaProjetos
                    vendaAtual={vendaAtual}
                    abrirRegistro={handleClickAbrir}
                    excluirRegistro={handleClickExcluir}
                    recarregaTabela={recarregaTabela}
                />
            </MDBox>
        </>
    )
}

export default CabecalhoProjetos;
