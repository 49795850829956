import React, { useContext, useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { Autocomplete, Checkbox, Collapse, Grid, IconButton, TextField, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import SearchIcon from '@mui/icons-material/Search';

import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import MessagesContext from 'hooks/MessagesContext';
import MDButton from 'components/MDButton';
import AuthContext from 'hooks/AuthContext';
import DialogoConfirma from 'componentes/DialogoConfirma';
import TabelaVendaServicos from './tabela';
import PesquisaProdutoGradeVenda from './pesquisaprodutograde';
import FormField from 'componentes/FormField';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';


const ServicosVenda = (props) => {

    const { control, setValue, getValues, vendaAtual, handleSubmit,
        recarregaVenda, openCollapseServico, handleClickCollapseServico, verificaVendaConcluida } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso } = messageContext;
    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();
    const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
    const [recarregaTabela, setRecarregaTabela] = useState(false);
    const opcaoDefault = [{ id: -1, value: -1, label: '<Selecione...>' }];
    const [confPesquisaDinamica, setConfPesquisaDinamica] = useState({});
    const [opcoesVendaProjetos, setOpcoesVendaProjetos] = useState([]);

    const [disabledBotoesCampos, setDisabledBotoesCampos] = useState(true);

    const ObjModeloServico = {
        id: { type: 'text' },
        servico_id: { type: 'text' },
        quantidade: { type: 'text' },
        valor_unitario: { type: 'text' },
        valor_total: { type: 'text' },
        observacao: { type: 'text' },
    }

    const validaRotaPermissao = async (operacao = '', silencioso = false) => {
        if ((operacao !== 'inserir') &&
            (operacao !== 'editar') &&
            (operacao !== 'excluir') &&
            (operacao !== 'listar')) {
            MensagemErro('Escolha a operação que deseja validar a permissão!');
            return false;
        }

        //validando a permissao
        try {
            await api.get(`${rotafechada()}venda_produtos/permissao/${operacao}`);
            return true;
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;

            if (silencioso === false) {
                MensagemErro(`Erro: ${msg}`);
            }
            return false;
        }
    }

    const novoRegistro = () => {
        //limpo os campos
        Object.keys(ObjModeloServico).forEach((nome) => {
            if (ObjModeloServico[nome].type === 'autocomplete') {
                setValue(`servicos.${nome}`, opcaoDefault[0])
            }
            else
                if (ObjModeloServico[nome].type === 'checkbox') {
                    setValue(`servicos.${nome}`, false)
                }
                else
                    if (ObjModeloServico[nome].type === 'text') {
                        setValue(`servicos.${nome}`, '')
                    }
        })
        setValue('servicos.nome_servico_id', '');
        setValue('servicos.venda_projeto_id', opcoesVendaProjetos.length > 0 ? { ...opcoesVendaProjetos[0] } : '');
    }

    const abrirRegistro = async (codigoAbrir = '') => {

        if (!(parseInt(codigoAbrir, 10) > 0)) {
            return;
        }

        try {
            let url = `${rotafechada()}venda_produtos?id=${codigoAbrir}`;
            const retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            console.log(data);

            if (!data.length > 0) {
                MensagemErro(`Venda/Servicos ID "${codigoAbrir}" não localizado`);
                return;
            }
            setValue('servicos.id', data[0].id);
            setValue('servicos.venda_id', data[0].venda_id);

            if (parseInt(data[0].venda_projeto_id, 10) > 0) {
                const vendaProjetoIdAbrir = opcoesVendaProjetos.filter((item) => parseInt(item.value, 10) === parseInt(data[0].venda_projeto_id, 10));
                if ((vendaProjetoIdAbrir) && (vendaProjetoIdAbrir.length > 0)) {
                    setValue('servicos.venda_projeto_id', { ...vendaProjetoIdAbrir[0] });
                }
            }
            else setValue('servicos.venda_projeto_id', opcoesVendaProjetos.length > 0 ? { ...opcoesVendaProjetos[0] } : '');

            setValue('servicos.servico_id', data[0].servico_id);
            setValue('servicos.nome_servico_id', data[0].Servico?.nome || '');
            setValue('servicos.quantidade', data[0].quantidade);
            setValue('servicos.valor_unitario', data[0].valor_unitario);
            setValue('servicos.valor_total', data[0].valor_total);
            setValue('servicos.observacao', data[0].observacao);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const excluirRegistro = async () => {

        setDialogoOpen({ ...dialogoOpen, visible: false });

        try {
            let url = `${rotafechada()}venda_produtos?id=${dialogoOpen.id}`;
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;

            if (!data.length > 0) {
                MensagemErro(`Venda/Servicos ID "${dialogoOpen.id}" não localizado`);
                return;
            }
            retorno = await api.delete(`${rotafechada()}venda_produtos/${dialogoOpen.id || 0}`);
            MensagemAviso(retorno?.data?.msg ? retorno?.data?.msg : 'Excluído!');
            novoRegistro();
            setRecarregaTabela(!recarregaTabela);
            recarregaVenda();
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const handleClickExcluir = async (id) => {

        if (disabledBotoesCampos) {//venda concluida
            MensagemErro('A venda está concluída!')
            return;
        }

        if (!(await validaRotaPermissao('excluir') === true)) {
            return;
        }
        setDialogoOpen({ ...dialogoOpen, visible: true, id });
    }

    const gravarRegistro = async (data) => {
        data.servicos.venda_projeto_id = data.servicos.venda_projeto_id?.value || '';

        if ((parseInt(data.servicos.venda_projeto_id, 10) < 0) || (data.servicos.venda_projeto_id === '')) {
            delete data.servicos.venda_projeto_id;
        }

        const dados = {
            ...data.servicos,
            empresa_id: sessaolocal.empresa_id || null,
            venda_id: vendaAtual
        }

        delete dados.id;
        delete dados.produto_grade_id;
        dados.quantidade = `${dados.quantidade}`.replace(',', '.');
        dados.valor_unitario = `${dados.valor_unitario}`.replace(',', '.');

        try {
            let url = `${rotafechada()}venda_produtos`;
            let retorno;
            if (parseInt(data.servicos.id, 10) > 0) {
                retorno = await api.put(`${url}/${data.servicos.id}`, dados);
            }
            else {
                retorno = await api.post(url, dados);
            }
            MensagemAviso(`Gravado com sucesso! ID: ${retorno.data.data.id}`);

            novoRegistro();
            recarregaVenda();
            setRecarregaTabela(!recarregaTabela);

        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
            return;
        }
    }

    const getNomeServico = useWatch({
        control,
        name: "servicos.nome_servico_id",
    });

    const handleClickPesquisaServicoGrade = async () => {

        const tabela_preco_id = getValues('tabela_preco_id').value || '';
        setConfPesquisaDinamica({ ...confPesquisaDinamica, tabela_preco_id, open: true });
    }

    const handleFuncaoSelecaoPesquisaDinamica = async (codigo, datasetPesquisa) => {
        /* Essa funcao é chamada pelo componente de pesquisa dinamica quando um registro é escolhido
        ele retorna os campos que foram configurados*/
        setConfPesquisaDinamica({ ...confPesquisaDinamica, open: false });
        setValue('servicos.servico_id', datasetPesquisa.id);

        const tabela_preco_id = getValues('tabela_preco_id').value || '';
        const servico_id = datasetPesquisa.id || '';
        await buscaDadosServicoPrecos(servico_id, tabela_preco_id);
        handleBlurQuantidade();

    }

    const buscaDadosServicoPrecos = async (servico_id = '', tabela_preco_id = '') => {
        try {
            if ((parseInt(servico_id, 10) > 0) && (parseInt(tabela_preco_id, 10) > 0)) {

                //reaproveito a rota do F9 da pesquisa porque ja tem preco, nome...
                let url = `${rotafechada()}servico_precos?servico_id=${servico_id}&tabela_preco_id=${tabela_preco_id}`;
                const retorno = await api.get(encodeURI(url));
                const { data } = retorno.data;
                if ((data) && (data?.length > 0)) {
                    setValue('servicos.servico_id', data[0].servico_id);
                    setValue('servicos.nome_servico_id', `${data[0].Servico.nome}`);
                    setValue('servicos.valor_unitario', data[0].valor_venda);
                    return;
                }
            }
            setValue('servicos.servico_id', '');
            setValue('servicos.nome_servico_id', '');
            setValue('servicos.valor_unitario', '');
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro na busca dos dados do produto: ${msg}`);
        }
    }

    const handleBlurServicoGrade = async () => {
        const tabela_preco_id = getValues('tabela_preco_id').value || '0';
        const servico_id = getValues('servicos.servico_id');
        await buscaDadosServicoPrecos(servico_id, tabela_preco_id);
        handleBlurQuantidade();
    }

    const handleBlurQuantidade = () => {

        let quantidade = getValues(`servicos.quantidade`);
        quantidade = `${quantidade}`.replace(',', '.');

        let valor_unitario = getValues(`servicos.valor_unitario`);
        valor_unitario = `${valor_unitario}`.replace(',', '.');

        if (!parseFloat(quantidade) > 0) {
            quantidade = 0;
        }

        if (!parseFloat(valor_unitario) > 0) {
            valor_unitario = 0;
        }

        const valor_total = (parseFloat(quantidade) * parseFloat(valor_unitario));
        setValue(`servicos.valor_total`, `${valor_total}`);
    }

    const buscaOpcoesVendaProjetos = async () => {
        try {
            let url = `${rotafechada()}venda_projetos?limit=-1&venda_id=${vendaAtual}`;
            const retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;

            let opcaoTemp = [];
            opcaoTemp.push({ id: -1, value: -1, label: '<Avulso>' });
            data.map((item) => {
                opcaoTemp.push({ value: item.id, label: `${item.id}-${item.Projeto.nome}/Ambiente:${item.ambiente_instalacao}` })
            })
            setOpcoesVendaProjetos(opcaoTemp);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const habilitaBotoes = async () => {
        const disabledBotoesTemp = await verificaVendaConcluida();

        setDisabledBotoesCampos(disabledBotoesTemp);
    }

    useEffect(() => {
        buscaOpcoesVendaProjetos();
        setRecarregaTabela(!recarregaTabela);
        habilitaBotoes();

    }, [openCollapseServico]);


    return (
        <MDBox p={2} bgColor='grey-100' mt={2} borderRadius="lg" coloredShadow='secondary'>

            <PesquisaProdutoGradeVenda
                open={confPesquisaDinamica.open || false}
                tabela_preco_id={confPesquisaDinamica.tabela_preco_id || ''}
                handleClose={
                    () => setConfPesquisaDinamica({ ...confPesquisaDinamica, open: false })
                }
                handleClick={handleFuncaoSelecaoPesquisaDinamica}
            />

            <DialogoConfirma
                open={dialogoOpen.visible}
                title={'Confirma Exclusão'}
                color={'error'}
                message={'Certeza que deseja excluir?'}
                handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
                handleConfirm={excluirRegistro}
            />

            <IconButton aria-label="expand row" size="small" onClick={() => handleClickCollapseServico(!openCollapseServico)}>
                {openCollapseServico ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                <Typography variant='h6' mb={2}>Servicos</Typography>
            </IconButton>

            <Collapse in={openCollapseServico} timeout="auto" unmountOnExit>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={2}>{/*id*/}
                                <Controller
                                    name={`servicos.id`}
                                    control={props.control}
                                    defaultValue=''
                                    render={({ field: { ref, ...props } }) =>
                                        <FormField

                                            autoComplete={false}
                                            type='text'
                                            label='Id'
                                            rows={1}
                                            multiline={false}
                                            required={false}
                                            disabled={true}
                                            inputProps={{
                                                disabled: true
                                            }}
                                            inputRef={ref}
                                            {...props}
                                        />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={10}>{/*venda_projeto_id*/}
                                <Controller
                                    name='servicos.venda_projeto_id'
                                    control={control}
                                    defaultValue={{ value: -1 }}
                                    render={({ field: { onChange, ...props } }) => (
                                        <AutoCompleteStyled
                                            options={opcoesVendaProjetos}
                                            getOptionLabel={(option) => option.label || ''}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            renderInput={(params) => <FormField {...params} label='Projeto da Venda' />}
                                            onChange={(_, data) => {
                                                return onChange(data);
                                            }}
                                            disabled={disabledBotoesCampos}
                                            {...props}

                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>{/*servico_id*/}
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={2}>{/*servico_id*/}
                                        <Controller
                                            name={'servicos.servico_id'}
                                            control={control}
                                            render={({ field: { ref, ...props } }) =>
                                                <FormField
                                                    type='text'
                                                    label='Serviço'
                                                    rows={1}
                                                    multiline={false}
                                                    required={true}
                                                    disabled={disabledBotoesCampos}
                                                    {...props}
                                                    inputProps={{
                                                        onKeyUp: (e) => {
                                                            e.preventDefault();
                                                            if (e.key === 'F9') {
                                                                handleClickPesquisaServicoGrade();
                                                            }
                                                        },
                                                        onBlur: (e) => {
                                                            e.preventDefault();
                                                            handleBlurServicoGrade();
                                                        },
                                                    }}
                                                    inputRef={ref}
                                                    InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                                />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={1}>{/*botao do servico_id*/}
                                        <MDBox width="100%" height='100%' display="flex" justifyContent='center' alignItems='center' >
                                            <SearchIcon disabled={disabledBotoesCampos} sx={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    handleClickPesquisaServicoGrade();
                                                }}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>{/*label do servico_id*/}
                                        <MDBox width="100%" height='100%' display="flex" alignSelf='flex-start' alignItems='center' >
                                            <Typography variant='caption'>{getNomeServico}</Typography>
                                        </MDBox>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={3}>{/*quantidade*/}
                                <Controller
                                    name={`servicos.quantidade`}
                                    control={props.control}
                                    defaultValue=''
                                    render={({ field: { ref, ...props } }) =>
                                        <FormField

                                            autoComplete={false}
                                            type='text'
                                            label='Quantidade'
                                            rows={1}
                                            multiline={false}
                                            required={false}
                                            disabled={disabledBotoesCampos}
                                            inputRef={ref}
                                            inputProps={{
                                                onKeyUp: (e) => {
                                                },
                                                onBlur: (e) => {
                                                    e.preventDefault();
                                                    handleBlurQuantidade();
                                                },
                                            }}
                                            {...props}

                                        />}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3}>{/*valor_unitario*/}
                                <Controller
                                    name={`servicos.valor_unitario`}
                                    control={props.control}
                                    defaultValue=''
                                    render={({ field: { ref, ...props } }) =>
                                        <FormField

                                            autoComplete={false}
                                            type='text'
                                            label='Valor'
                                            rows={1}
                                            multiline={false}
                                            required={false}
                                            inputRef={ref}
                                            // disabled={true}
                                            InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                            inputProps={{
                                                // disabled: true
                                                onBlur: (e) => {
                                                    e.preventDefault();
                                                    handleBlurQuantidade();
                                                },
                                            }}
                                            {...props}

                                        />}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3}>{/*valor_total*/}
                                <Controller
                                    name={`servicos.valor_total`}
                                    control={props.control}
                                    defaultValue=''
                                    render={({ field: { ref, ...props } }) =>
                                        <FormField

                                            autoComplete={false}
                                            type='text'
                                            label='Valor Total'
                                            rows={1}
                                            multiline={false}
                                            required={false}
                                            inputRef={ref}
                                            disabled={true}
                                            InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                            inputProps={{
                                                disabled: true
                                            }}
                                            {...props}

                                        />}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12}>{/*observacao*/}
                                <Controller
                                    name={`servicos.observacao`}
                                    control={props.control}
                                    defaultValue=''
                                    render={({ field: { ref, ...props } }) =>
                                        <FormField
                                            autoComplete={false}
                                            type='text'
                                            label='Observação'
                                            rows={1}
                                            multiline={false}
                                            required={false}
                                            disabled={disabledBotoesCampos}
                                            inputRef={ref}
                                            {...props}

                                        />}
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

                <MDBox width="100%" display="flex" justifyContent="flex-end" mt={2}>
                    <MDButton disabled={disabledBotoesCampos} variant="gradient" color="info" onClick={() => handleSubmit(gravarRegistro)()} sx={{ ml: 2 }}>Gravar</MDButton>
                    <MDButton disabled={disabledBotoesCampos} variant="gradient" color="info" onClick={novoRegistro} sx={{ ml: 2 }}>Novo</MDButton>
                </MDBox>

                <MDBox width="100%" display="flex" mt={2}>
                    <TabelaVendaServicos
                        vendaAtual={vendaAtual}
                        abrirRegistro={abrirRegistro}
                        excluirRegistro={handleClickExcluir}
                        recarregaTabela={recarregaTabela}
                        opcoesVendaProjetos={opcoesVendaProjetos}
                    />
                </MDBox>

            </Collapse>
        </MDBox >
    )
}

export default ServicosVenda;
