import React, { useState, useEffect, useContext } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DetalheParcela from "../../contas/DetalheParcela";
import DetalheTransferencia from "../../transferencia/DetalheTransferencia"
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MessagesContext from "hooks/MessagesContext";
import TabelaPadrao from "componentes/TabelaPadrao";
import { Grid, Icon, Tooltip, Divider, Menu, MenuItem, Card, Box } from "@mui/material";
import AuthContext from 'hooks/AuthContext';
import { formatDate } from "commons/utils";
import { formataValor } from "commons/utils";
import Caixa from '../../../../assets/images/bank/nao-e-conta-corrente.svg'
import Bank from '../../../../assets/images/bank/outro-banco.svg'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { startOfWeek, endOfWeek, addMonths, addDays, addWeeks, addYears, endOfMonth, startOfMonth, startOfYear, endOfYear, subMonths, subDays, subWeeks, subYears } from 'date-fns'
import format from 'date-fns/format'
import { ptBR } from "date-fns/locale";
import FormField from "componentes/FormField";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function DialogoExtrato(props) {

    const { open, handleCancel, title, handleClickTransferencia, handleClickDespesa, handleClickReceita, recarregaTabela, setRecarregaTabela } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera, MensagemAlerta } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    /*****************Menu Item*******************/
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDetalhe, setOpenDetalhe] = useState(false);

    const [anchorElFiltro, setAnchorElFiltro] = useState(null);
    const [openDetalheFiltro, setOpenDetalheFiltro] = useState(false);
    const [openDetalheFiltroData, setOpenDetalheFiltroData] = useState(false);
    const [dataInicial, setDataInicial] = useState(null);
    const [dataFinal, setDataFinal] = useState(null);
    const [filtro, setFiltro] = useState(
        {
            labelOriginal: endOfMonth(new Date()),
            labelFormatada: format(endOfMonth(new Date()), "MMMM 'de' yyyy", { locale: ptBR }),

            dataInicialOriginal: startOfMonth(new Date()),
            dataInicialFormatada: (format(startOfMonth(new Date()), "yyyy-MM-dd 00:00:00", { locale: ptBR })),

            dataFinalOriginal: endOfMonth(new Date()),
            dataFinalFormatada: (format(endOfMonth(new Date()), "yyyy-MM-dd 23:59:59", { locale: ptBR })),
        }
    );

    const [detalheParcelaOpen, setDetalheParcelaOpen] = useState({ visible: false, nome: '' });
    const [detalheTransferenciaOpen, setDetalheTransferenciaOpen] = useState({ visible: false, title: '' });
    const [codigoCarregado, setCodigoCarregado] = useState(0);
    const [tipoConta, setTipoConta] = useState('');
    const [backNext, setBackNext] = useState(true);
    const [periodo, setPeriodo] = useState('mes');

    //Banco
    const [saldoAtual, setSaldoAtual] = useState(0);
    let saldoInicial = 0;
    let saldoPeriodo = 0;

    //TABELA
    const [colunasTable, setColunasTable] = React.useState([]);
    const [linhas, setLinhas] = React.useState([]);
    const [countAll, setCountAll] = React.useState(0);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const buscaDados = async () => {
        try {
            MostraTelaEspera()
            /*DADOS TABELA*/
            let complementoUrl = '';

            if (page > 0) {
                complementoUrl = `${complementoUrl}&offset=${page * rowsPerPage}`;
            }

            if (orderBy) {
                complementoUrl = `${complementoUrl}&order=${orderBy}`;
                if (order) {
                    complementoUrl = `${complementoUrl}&direction=${order}`;
                }
            }

            let url = `${rotafechada()}lancamento_banco/movimentacao?banco_id=${props.codigoAbrir}&data1=${new Date(filtro.dataInicialFormatada)}&data2=${new Date(filtro.dataFinalFormatada)}`
            //console.log('URL', url)
            const retornoDetalheParcela = await api.get(url);
            const { data, countAll: countAllretorno, dataSaldo } = retornoDetalheParcela.data;
            const dadostemp = [];

            // console.log("Data movimentações:", data)
            //console.log("dataSaldo:", dataSaldo)
            setSaldoAtual(dataSaldo.saldoAtual);
            saldoInicial = (dataSaldo.saldoInicial);
            saldoPeriodo = (dataSaldo.saldoPeriodo);
            const colunasTabletemp = [
                // {
                //     id: 'id',
                //     numeric: false,
                //     disablePadding: true,
                //     label: 'id',
                //     disableOrder: true
                // },
                // {
                //     id: 'transferencia_id',
                //     numeric: false,
                //     disablePadding: true,
                //     label: 'transferencia_id',
                //     disableOrder: true
                // },
                {
                    id: 'data',
                    numeric: false,
                    disablePadding: true,
                    label: 'Data',
                    disableOrder: true
                },
                {
                    id: 'descricao',
                    numeric: false,
                    disablePadding: true,
                    label: 'Descrição',
                    disableOrder: true
                },
                {
                    id: 'simbolo',
                    numeric: false,
                    disablePadding: true,
                    label: '',
                    disableOrder: true
                },
                {
                    id: 'valor',
                    numeric: false,
                    disablePadding: true,
                    label: '',
                    disableOrder: true
                },
            ]
            //mapeando os dados para a "rows"
            dadostemp.push({ //Saldo inicial
                descricao: <strong>Saldo Inicial</strong>,
                data: formatDate(new Date(filtro.dataInicialFormatada), 'DD/MM/YYYY'),
                valor:
                    <>
                        <MDBox display="flex" justifyContent="flex-end" flexDirection='row'>
                            {saldoInicial >= 0 ? <MDTypography variant="body1" fontWeight='bold' pl={1} textTransform="capitalize" color={'success'}>{formataValor(saldoInicial)}</MDTypography> : <MDTypography variant="body2" fontWeight='bold' pl={1} textTransform="capitalize" color={'error'}>{formataValor(parseFloat(saldoInicial))}</MDTypography>}
                        </MDBox>
                    </>
            })
            data.map((item, index) => { //dados da tabela
                dadostemp.push({
                    id: item?.id || 0,
                    transferencia_id: item?.transferencia_id,
                    data: formatDate(new Date(item.data), 'DD/MM/YYYY'),
                    descricao: item.observacao === 'Transferencia' ?
                        <Box>
                            <MDBox width='100%' display='flex' justifyContent="flex-start" flexDirection='row'>
                                <MDTypography onClick={() => { handleClickDetalheTransferencia(item.transferencia_id) }} sx={{ cursor: "pointer" }} variant="body2" textTransform="capitalize">{`${item.transferencia}`}</MDTypography>
                            </MDBox>
                            <MDBox width='100%' display='flex' justifyContent="flex-start" flexDirection='row'>
                                <MDTypography onClick={() => { handleClickDetalheTransferencia(item.transferencia_id) }} sx={{ cursor: "pointer" }} variant="caption" textTransform="capitalize">{<><strong>Descrição:</strong> {item.historico}</>}</MDTypography>
                            </MDBox>
                        </Box>
                        : item.observacao === 'saldo_inicial' ? <MDTypography variant="body2" textTransform="capitalize">{'Lançamento inicial'}</MDTypography> :
                        <MDTypography onClick={() => { handleClickDetalheParcela(item.parcela_id, item.valor) }} sx={{ cursor: "pointer" }} variant="body2" textTransform="capitalize">{`${item.nome} - ${item.razao_social}`}</MDTypography>,
                    simbolo: item.observacao === 'Transferencia' ? <SwapVertIcon fontSize="medium" color={'info'} /> : item.valor > 0 ? <KeyboardDoubleArrowUpIcon fontSize="medium" color={'success'} /> : <KeyboardDoubleArrowDownIcon fontSize="medium" color={'error'} />,
                    valor:
                        <>
                            <MDBox display="flex" justifyContent="flex-end" flexDirection='row'>
                                {item.valor > 0 ? <MDTypography variant="body2" pl={1} textTransform="capitalize" color={'success'}>{formataValor(item.valor)}</MDTypography> : <MDTypography variant="body2" pl={1} textTransform="capitalize" color={'error'}>{formataValor(parseFloat(item.valor))}</MDTypography>}
                            </MDBox>
                        </>

                })
            })

            dadostemp.push({ //Saldo final
                descricao: <strong>Saldo Final</strong>,
                data: formatDate(new Date(filtro.dataFinalFormatada), 'DD/MM/YYYY'),
                valor:
                    <>
                        <MDBox display="flex" justifyContent="flex-end" flexDirection='row'>
                            {saldoPeriodo >= 0 ? <MDTypography variant="body2" fontWeight='bold' pl={1} textTransform="capitalize" color={'success'}>{formataValor(saldoPeriodo)}</MDTypography> : <MDTypography variant="body2" fontWeight='bold' pl={1} textTransform="capitalize" color={'error'}>{formataValor(parseFloat(saldoPeriodo))}</MDTypography>}
                        </MDBox>
                    </>
            })

            setColunasTable([...colunasTabletemp]);
            setLinhas(dadostemp);
            setCountAll(countAllretorno);
            FechaTelaEspera()
            /*DADOS TABELA*/

        } catch (error) {
            FechaTelaEspera()
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenDetalhe(false)
    };

    const handleCloseFiltro = () => {
        setAnchorElFiltro(null);
        setOpenDetalheFiltro(false)
    };

    const handleCloseFiltroData = () => {
        setAnchorElFiltro(null);
        setOpenDetalheFiltroData(false)
    };

    const handleClickDetalheParcela = (id, valor) => {

        //console.log("id e valor", id, valor)
        if (id !== 0) {
            setCodigoCarregado(id);
            setTipoConta(valor > 0 ? 'C' : 'D')
            setDetalheParcelaOpen({ ...detalheParcelaOpen, visible: true, nome: 'Detalhes da parcela ' });
        }

    }

    const handleClickDetalheTransferencia = (id) => {

        if (id !== 0) {
            setCodigoCarregado(id);
            setDetalheTransferenciaOpen({ ...detalheTransferenciaOpen, visible: true, title: 'Detalhes da transferência ' });
        }

    }

    const handleClickProximo = () => {

        let filtroTemp = filtro

        if (periodo === 'hoje') {
            setFiltro(
                {
                    labelOriginal: (addDays(filtroTemp.dataInicialOriginal, 1)),
                    labelFormatada: format(addDays(filtroTemp.dataInicialOriginal, 1), "dd 'de' MMMM yyyy", { locale: ptBR }),

                    dataInicialOriginal: addDays(filtroTemp.dataInicialOriginal, 1),
                    dataInicialFormatada: format(addDays(filtroTemp.dataInicialOriginal, 1), "yyyy-MM-dd 00:00:00", { locale: ptBR }),

                    dataFinalOriginal: addDays(filtroTemp.dataFinalOriginal, 1),
                    dataFinalFormatada: format(addDays(filtroTemp.dataFinalOriginal, 1), "yyyy-MM-dd 23:59:59", { locale: ptBR }),
                }
            )
        }
        if (periodo === 'semana') {
            setFiltro(
                {
                    labelOriginal: `${format(addWeeks(filtroTemp.dataInicialOriginal, 1), "dd/MM/yyyy")} até ${format(addWeeks(filtroTemp.dataFinalOriginal, 1), "dd/MM/yyyy")}`,
                    labelFormatada: `${format(addWeeks(filtroTemp.dataInicialOriginal, 1), "dd/MM/yyyy")} até ${format(addWeeks(filtroTemp.dataFinalOriginal, 1), "dd/MM/yyyy")}`,

                    dataInicialOriginal: addWeeks(filtroTemp.dataInicialOriginal, 1),
                    dataInicialFormatada: format(addWeeks(filtroTemp.dataInicialOriginal, 1), "yyyy-MM-dd 00:00:00", { locale: ptBR }),

                    dataFinalOriginal: addWeeks(filtroTemp.dataFinalOriginal, 1),
                    dataFinalFormatada: format(addWeeks(filtroTemp.dataFinalOriginal, 1), "yyyy-MM-dd 23:59:59", { locale: ptBR }),
                }
            )
        }
        if (periodo === 'mes') {
            setFiltro(
                {
                    labelOriginal: endOfMonth(addMonths(filtroTemp.dataInicialOriginal, 1)),
                    labelFormatada: format(endOfMonth(addMonths(filtroTemp.dataInicialOriginal, 1)), "MMMM 'de' yyyy", { locale: ptBR }),

                    dataInicialOriginal: addMonths(filtroTemp.dataInicialOriginal, 1),
                    dataInicialFormatada: format(addMonths(filtroTemp.dataInicialOriginal, 1), "yyyy-MM-dd 00:00:00", { locale: ptBR }),

                    dataFinalOriginal: endOfMonth(addMonths(filtroTemp.dataFinalOriginal, 1)),
                    dataFinalFormatada: format(endOfMonth(addMonths(filtroTemp.dataFinalOriginal, 1)), "yyyy-MM-dd 23:59:59", { locale: ptBR }),
                }
            )
        }
        if (periodo === 'ano') {
            setFiltro(
                {
                    labelOriginal: endOfYear(addYears(filtroTemp.dataInicialOriginal, 1)),
                    labelFormatada: format(endOfYear(addYears(filtroTemp.dataInicialOriginal, 1)), "yyyy", { locale: ptBR }),

                    dataInicialOriginal: addYears(filtroTemp.dataInicialOriginal, 1),
                    dataInicialFormatada: format(addYears(filtroTemp.dataInicialOriginal, 1), "yyyy-MM-dd 00:00:00", { locale: ptBR }),

                    dataFinalOriginal: addYears(filtroTemp.dataFinalOriginal, 1),
                    dataFinalFormatada: format(addYears(filtroTemp.dataFinalOriginal, 1), "yyyy-MM-dd 23:59:59", { locale: ptBR }),
                }
            )
        }

    }

    const handleClickAnterior = () => {

        let filtroTemp = filtro
        if (periodo === 'hoje') {
            setFiltro(
                {
                    labelOriginal: (subDays(filtroTemp.dataInicialOriginal, 1)),
                    labelFormatada: format(subDays(filtroTemp.dataInicialOriginal, 1), "dd 'de' MMMM yyyy", { locale: ptBR }),

                    dataInicialOriginal: subDays(filtroTemp.dataInicialOriginal, 1),
                    dataInicialFormatada: format(subDays(filtroTemp.dataInicialOriginal, 1), "yyyy-MM-dd 00:00:00", { locale: ptBR }),

                    dataFinalOriginal: subDays(filtroTemp.dataFinalOriginal, 1),
                    dataFinalFormatada: format(subDays(filtroTemp.dataFinalOriginal, 1), "yyyy-MM-dd 23:59:59", { locale: ptBR }),
                }
            )
        }
        if (periodo === 'semana') {
            setFiltro(
                {
                    labelOriginal: `${format(subWeeks(filtroTemp.dataInicialOriginal, 1), "dd/MM/yyyy")} até ${format(subWeeks(filtroTemp.dataFinalOriginal, 1), "dd/MM/yyyy")}`,
                    labelFormatada: `${format(subWeeks(filtroTemp.dataInicialOriginal, 1), "dd/MM/yyyy")} até ${format(subWeeks(filtroTemp.dataFinalOriginal, 1), "dd/MM/yyyy")}`,

                    dataInicialOriginal: subWeeks(filtroTemp.dataInicialOriginal, 1),
                    dataInicialFormatada: format(subWeeks(filtroTemp.dataInicialOriginal, 1), "yyyy-MM-dd 00:00:00", { locale: ptBR }),

                    dataFinalOriginal: subWeeks(filtroTemp.dataFinalOriginal, 1),
                    dataFinalFormatada: format(subWeeks(filtroTemp.dataFinalOriginal, 1), "yyyy-MM-dd 23:59:59", { locale: ptBR }),
                }
            )
        }
        if (periodo === 'mes') {
            setFiltro(
                {
                    labelOriginal: endOfMonth(subMonths(filtroTemp.dataInicialOriginal, 1)),
                    labelFormatada: format(endOfMonth(subMonths(filtroTemp.dataInicialOriginal, 1)), "MMMM 'de' yyyy", { locale: ptBR }),

                    dataInicialOriginal: subMonths(filtroTemp.dataInicialOriginal, 1),
                    dataInicialFormatada: format(subMonths(filtroTemp.dataInicialOriginal, 1), "yyyy-MM-dd 00:00:00", { locale: ptBR }),

                    dataFinalOriginal: endOfMonth(subMonths(filtroTemp.dataFinalOriginal, 1)),
                    dataFinalFormatada: format(endOfMonth(subMonths(filtroTemp.dataFinalOriginal, 1)), "yyyy-MM-dd 23:59:59", { locale: ptBR }),
                }
            )
        }
        if (periodo === 'ano') {
            setFiltro(
                {
                    labelOriginal: endOfYear(subYears(filtroTemp.dataInicialOriginal, 1)),
                    labelFormatada: format(endOfYear(subYears(filtroTemp.dataInicialOriginal, 1)), "yyyy", { locale: ptBR }),

                    dataInicialOriginal: subYears(filtroTemp.dataInicialOriginal, 1),
                    dataInicialFormatada: format(subYears(filtroTemp.dataInicialOriginal, 1), "yyyy-MM-dd 00:00:00", { locale: ptBR }),

                    dataFinalOriginal: subYears(filtroTemp.dataFinalOriginal, 1),
                    dataFinalFormatada: format(subYears(filtroTemp.dataFinalOriginal, 1), "yyyy-MM-dd 23:59:59", { locale: ptBR }),
                }
            )
        }

    }

    const limpaImputs = () => {
        setFiltro(
            {
                labelOriginal: endOfMonth(new Date()),
                labelFormatada: format(endOfMonth(new Date()), "MMMM 'de' yyyy", { locale: ptBR }),

                dataInicialOriginal: startOfMonth(new Date()),
                dataInicialFormatada: (format(startOfMonth(new Date()), "yyyy-MM-dd 00:00:00", { locale: ptBR })),

                dataFinalOriginal: endOfMonth(new Date()),
                dataFinalFormatada: (format(endOfMonth(new Date()), "yyyy-MM-dd 23:59:59", { locale: ptBR })),
            }
        );
    }

    useEffect(() => {
        if (open == true) {
            buscaDados();
        }
    }, [open, recarregaTabela, filtro])

    return (
        <>
            <Menu //Menu adicionar

                anchorEl={anchorEl}
                open={openDetalhe}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem
                    dense={true}
                    //disabled={situacaoParcela == 'PAGO' ? true : false}
                    onClick={() => {
                        setOpenDetalhe(false)
                        handleClickReceita()
                    }}>
                    Receita
                </MenuItem>

                <MenuItem
                    dense={true}
                    //disabled={situacaoParcela == 'PAGO' ? true : false}
                    onClick={() => {
                        setOpenDetalhe(false)
                        handleClickDespesa()
                    }}>
                    Despesa
                </MenuItem>

                <MenuItem
                    dense={true}
                    //disabled={situacaoParcela == 'PAGO' ? true : false}
                    onClick={() => {
                        setOpenDetalhe(false)
                        handleClickTransferencia()
                    }}>
                    Transferência
                </MenuItem>

            </Menu>

            <Menu //Filtro Período personalizado
                anchorEl={anchorElFiltro}
                open={openDetalheFiltroData}
                onClose={handleCloseFiltroData}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >

                <Card>
                    <Box paddingBottom={1} paddingTop={1}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                            <DesktopDatePicker
                                required={true}
                                //disabled={desabilitaData}
                                label='Data Inicial'
                                inputFormat="dd/MM/yyyy"
                                value={dataInicial}
                                onChange={(e) => setDataInicial(e)}
                                renderInput={(params) => <FormField {...params} required />}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box>
                        até
                    </Box>
                    <Box paddingBottom={1} paddingTop={1}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                            <DesktopDatePicker
                                required={true}
                                //disabled={desabilitaData}
                                label='Data Final'
                                inputFormat="dd/MM/yyyy"
                                value={dataFinal}
                                onChange={(e) => setDataFinal(e)}
                                renderInput={(params) => <FormField {...params} required />}
                            />
                        </LocalizationProvider>
                    </Box>
                    <MDButton
                        variant="gradient"
                        color={'info'}
                        onClick={() => {
                            setBackNext(false);
                            setFiltro(
                                {
                                    labelOriginal: `${format(dataInicial, "dd 'de' MMMM yyyy", { locale: ptBR })} até ${format(dataFinal, "dd 'de' MMMM yyyy", { locale: ptBR })}`,
                                    labelFormatada: `${format(dataInicial, "dd 'de' MMMM yyyy", { locale: ptBR })} até ${format(dataFinal, "dd 'de' MMMM yyyy", { locale: ptBR })}`,

                                    dataInicialOriginal: dataInicial,
                                    dataInicialFormatada: (format(dataInicial, "yyyy-MM-dd 00:00:00")),

                                    dataFinalOriginal: dataFinal,
                                    dataFinalFormatada: (format(dataFinal, "yyyy-MM-dd 23:59:59"))
                                }
                            )
                            setOpenDetalheFiltroData(false)

                        }}
                    >Aplicar filtro </MDButton>
                </Card>

            </Menu>

            <Menu //Filtro
                anchorEl={anchorElFiltro}
                open={openDetalheFiltro}
                onClose={handleCloseFiltro}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >

                <MenuItem //Hoje
                    dense={true}
                    onClick={() => {
                        setBackNext(true);
                        setPeriodo('hoje')
                        setFiltro(
                            {
                                labelOriginal: new Date(),
                                labelFormatada: format(new Date(), "dd 'de' MMMM yyyy", { locale: ptBR }),

                                dataInicialOriginal: new Date(),
                                dataInicialFormatada: (format(new Date(), "yyyy-MM-dd 00:00:00")),

                                dataFinalOriginal: new Date(),
                                dataFinalFormatada: (format(new Date(), "yyyy-MM-dd 23:59:59"))
                            }
                        )
                        setOpenDetalheFiltro(false)
                    }}>
                    Hoje
                </MenuItem>

                <MenuItem //Semana
                    dense={true}
                    onClick={() => {
                        setBackNext(true);
                        setPeriodo('semana')
                        setFiltro(
                            {
                                labelOriginal: `${format(startOfWeek(new Date()), "dd/MM/yyyy")} até ${format(endOfWeek(new Date()), "dd/MM/yyyy")}`,
                                labelFormatada: `${format(startOfWeek(new Date()), "dd/MM/yyyy")} até ${format(endOfWeek(new Date()), "dd/MM/yyyy")}`,

                                dataInicialOriginal: startOfWeek(new Date()),
                                dataInicialFormatada: (format(startOfWeek(new Date()), "yyyy-MM-dd 00:00:00")),

                                dataFinalOriginal: endOfWeek(new Date()),
                                dataFinalFormatada: (format(endOfWeek(new Date()), "yyyy-MM-dd 23:59:59"))
                            }
                        )
                        setOpenDetalheFiltro(false)
                    }}>
                    Essa semana
                </MenuItem>

                <MenuItem //Mês
                    dense={true}
                    onClick={() => {
                        setBackNext(true);
                        setPeriodo('mes')
                        setFiltro(
                            {
                                labelOriginal: endOfMonth(new Date()),
                                labelFormatada: format(endOfMonth(new Date()), "MMMM 'de' yyyy", { locale: ptBR }),

                                dataInicialOriginal: startOfMonth(new Date()),
                                dataInicialFormatada: (format(startOfMonth(new Date()), "yyyy-MM-dd 00:00:00")),

                                dataFinalOriginal: endOfMonth(new Date()),
                                dataFinalFormatada: (format(endOfMonth(new Date()), "yyyy-MM-dd 23:59:59"))
                            }
                        )
                        setOpenDetalheFiltro(false)
                    }}>
                    Este mês
                </MenuItem>

                <MenuItem //Ano
                    dense={true}
                    onClick={() => {
                        setBackNext(true);
                        setPeriodo('ano')
                        setFiltro(
                            {
                                labelOriginal: endOfYear(new Date()),
                                labelFormatada: format(endOfYear(new Date()), "yyyy", { locale: ptBR }),

                                dataInicialOriginal: startOfYear(new Date()),
                                dataInicialFormatada: (format(startOfYear(new Date()), "yyyy-MM-dd 00:00:00")),

                                dataFinalOriginal: endOfYear(new Date()),
                                dataFinalFormatada: (format(endOfYear(new Date()), "yyyy-MM-dd 23:59:59"))
                            }
                        )
                        setOpenDetalheFiltro(false)
                    }}>
                    Este ano
                </MenuItem>

                <MenuItem
                    dense={true}
                    onClick={() => {
                        setBackNext(false);
                        setOpenDetalheFiltro(false)
                        setOpenDetalheFiltroData(true)
                    }}
                >
                    Período personalizado
                </MenuItem>

            </Menu>

            {detalheParcelaOpen.visible == true &&
                <DetalheParcela
                    open={detalheParcelaOpen.visible}
                    title={detalheParcelaOpen.nome}
                    message={'Mensagem'}
                    tipo={tipoConta}
                    setCodigoAbrir={setCodigoCarregado}
                    recarregaTabela={recarregaTabela}
                    setRecarregaTabela={setRecarregaTabela}
                    codigoAbrir={codigoCarregado}
                    handleCancel={() => {
                        setDetalheParcelaOpen({ ...detalheParcelaOpen, visible: false, nome: '' })
                        //setAba(0);
                    }}
                />
            }
            {detalheTransferenciaOpen.visible == true &&
                <DetalheTransferencia
                    open={detalheTransferenciaOpen.visible}
                    title={detalheTransferenciaOpen.title}
                    message={'Mensagem'}
                    setCodigoAbrir={setCodigoCarregado}
                    codigoAbrir={codigoCarregado}
                    recarregaTabela={recarregaTabela}
                    setRecarregaTabela={setRecarregaTabela}
                    handleCancel={() => {
                        setDetalheTransferenciaOpen({ ...detalheTransferenciaOpen, visible: false, nome: '' })
                        //setAba(0);
                    }}
                />
            }


            <Dialog
                open={open}
                onClose={() => {
                    handleCancel()
                    limpaImputs()
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullScreen
            >
                <DialogTitle id="alert-dialog-title" sx={{ padding: '5px' }}>{/*HEADER*/}
                    <MDBox width="100%">
                        <MDBox display="flex" justifyContent="space-between">
                            <MDBox display="flex" justifyContent="space-start" flexDirection='column'>
                                <MDTypography fontWeight='bold' ml={5} mt={1} variant='h4'>{'Movimentações'}</MDTypography>
                            </MDBox>

                            <Tooltip title="Fechar (Esc)">
                                <MDButton size="large" sx={{ padding: 0 }} onClick={() => {
                                    handleCancel()
                                    limpaImputs()
                                }}>
                                    <Icon>close</Icon>
                                </MDButton>

                            </Tooltip>
                        </MDBox>
                    </MDBox>
                </DialogTitle>


                <DialogContent dividers sx={{ backgroundColor: "#f1f4f9", borderColor: '#7bb5e4' }}>
                    <DialogContentText id="alert-dialog-description">

                        <MDBox ml={2} mr={2} pb={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/* CONTA */}
                            <Grid container spacing={3} mt={1} pl={2} pr={2}>

                                <Grid item xs={12} sm={12}> {/* TITULO*/}
                                    <MDBox width='100%' display='flex' alignItems='space-between' flexDirection='row'>
                                        <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='row'>{/* Nome do caixa e saldo */}
                                            <img src={Caixa} alt="Banco"></img>
                                            {/* <img src={title.length > 0 ?
                                            `https://app.contaazul.com/versions/current/assets/images/bank/50x50/${title}.svg`
                                            : `https://app.contaazul.com/versions/current/assets/images/bank/50x50/outro-banco.svg`}
                                            alt="Logo Banco" /> */}
                                            <MDBox pl={3} width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                                <MDTypography variant='subtitle1' fontWeight="bold" textTransform="capitalize" pb={1}>{title}</MDTypography>
                                                <MDTypography variant='subtitle2' textTransform="capitalize" >{`Saldo atual: ${formataValor(saldoAtual)}`}</MDTypography>
                                                {/* <MDTypography variant='subtitle2' textTransform="capitalize" >{`Saldo inicial: ${formataValor(saldoInicial)}`}</MDTypography> */}
                                                {/* <MDTypography variant='subtitle2' textTransform="capitalize" >{`Saldo periodo: ${formataValor(saldoPeriodo)}`}</MDTypography> */}
                                            </MDBox>
                                        </MDBox>

                                        <MDBox width='100%' display='flex' alignItems='center' flexDirection='column'> {/* Período */}
                                            <MDTypography variant='subtitle2' textTransform="capitalize" fontWeight='bold'>{`Período`}</MDTypography>
                                            <Box justifyContent='center' width='100%' display='flex' alignItems='center' flexDirection='row'>

                                                {backNext === true &&
                                                    <Tooltip title="Anterior" placement="top" disableInteractive>
                                                        <MDButton
                                                            onClick={handleClickAnterior}
                                                            variant="contained"
                                                            color={'info'}
                                                            sx={{ borderBottomLeftRadius: '3px', borderTopLeftRadius: '3px', borderBottomRightRadius: '0px', borderTopRightRadius: '0px', borderColor: "#c9d3dd" }}
                                                        >
                                                            <ArrowBackIosIcon />
                                                        </MDButton>
                                                    </Tooltip>
                                                }
                                                <Box pl={0.2} pr={0.2}>
                                                    <Tooltip title="Clique aqui para selecionar outro período" placement="top" disableInteractive>
                                                        <MDButton
                                                            variant="contained"
                                                            color={'info'}
                                                            onClick={(e) => {
                                                                setAnchorElFiltro(e.currentTarget);
                                                                setOpenDetalheFiltro(true)
                                                            }}

                                                            //sx={{ borderRadius: '0px', borderColor: 'white' }}
                                                            sx={{ borderRadius: `${backNext == true ? '0px' : '3px'}`, borderColor: 'white' }}
                                                            endIcon={<KeyboardArrowDownIcon />}
                                                        //disableElevation
                                                        >
                                                            {filtro.labelFormatada}
                                                        </MDButton>
                                                    </Tooltip>
                                                </Box>
                                                {backNext === true &&
                                                    <Tooltip title="Próximo" placement="top" disableInteractive>
                                                        <MDButton
                                                            onClick={handleClickProximo}
                                                            variant="contained"
                                                            color={'info'}
                                                            sx={{ borderBottomRightRadius: '3px', borderTopRightRadius: '3px', borderBottomLeftRadius: '0px', borderTopLeftRadius: '0px' }}>
                                                            <ArrowForwardIosIcon />
                                                        </MDButton>
                                                    </Tooltip>
                                                }
                                            </Box>
                                        </MDBox>

                                        <MDBox width='100%' display='flex' alignItems='flex-end' flexDirection='column' pb={1}> {/* Adicionar */}
                                            <MDTypography variant='subtitle2' > </MDTypography>
                                            <MDButton
                                                variant="gradient"
                                                color={'info'}
                                                onClick={(e) => {
                                                    setAnchorEl(e.currentTarget);
                                                    setOpenDetalhe(true)
                                                }}
                                                sx={{ borderRadius: '3px' }}
                                                endIcon={<KeyboardArrowDownIcon />}
                                            //disableElevation
                                            >
                                                Adicionar
                                            </MDButton>
                                        </MDBox>
                                    </MDBox>

                                    <Divider
                                        sx={{ bgcolor: (theme) => theme.palette.divider }}
                                        style={{
                                            marginTop: 5,
                                            marginBottom: 10,
                                            border: "none",
                                            height: 2,
                                            margin: 0,
                                        }}
                                    />
                                </Grid>


                                <Grid item xs={12} sm={12}>  {/* TABELA*/}
                                    <TabelaPadrao
                                        colunas={colunasTable}
                                        linhas={linhas}
                                        countAll={countAll}
                                        page={page}
                                        setPage={setPage}
                                        rowsPerPage={rowsPerPage}
                                        setRowsPerPage={setRowsPerPage}
                                        order={order}
                                        setOrder={setOrder}
                                        orderBy={orderBy}
                                        setOrderBy={setOrderBy}
                                    />
                                </Grid>

                            </Grid>
                        </MDBox>
                    </DialogContentText>
                </DialogContent>

                <DialogActions sx={{ padding: '7px', margin: 0.5 }}> {/*FOOTER*/}
                    <MDBox width="100%">
                        <MDBox width="100%" display="flex" justifyContent="space-between">
                            <MDButton onClick={() => {
                                handleCancel()
                                limpaImputs()
                            }} variant="gradient" type="button" color="secondary">
                                voltar
                            </MDButton>
                            {/* <MDButton
                                onClick={handleClickGravar}
                                variant="gradient"
                                type="submit"
                                //type="button" 
                                color="info">
                                {'salvar'}
                            </MDButton> */}
                        </MDBox>
                    </MDBox>
                </DialogActions>
            </Dialog>
        </>
    );
}