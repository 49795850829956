import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box } from '@mui/system';
import { Checkbox, Divider, Icon } from '@mui/material';
import MDButton from 'components/MDButton';
import AuthContext from "hooks/AuthContext";



export default function DialogoOpcoesImpressao(props) {

  const { onClose, value: valueProp, open, title, vendaAtual, ...other } = props;

  const [value, setValue] = React.useState(valueProp);
  const [downloadArquivo, setDownloadArquivo] = React.useState(false);
  const [comMedidas, setComMedidas] = React.useState(true);
  const [comValores, setComValores] = React.useState(true);
  const radioGroupRef = React.useRef(null);
  const authContext = React.useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();

  let options = [];

  if (parseInt(vendaAtual, 10) > 0) {
    options.push({ value: 0, label: 'Proposta Compacta' });
    options.push({ value: 1, label: 'Proposta Completa' });
    options.push({ value: 2, label: 'Lista de Materiais da Venda' });
    options.push({ value: 6, label: 'Lista de Materiais da Venda por Projetos' });
    options.push({ value: 3, label: 'Custo e Lucro dos Itens da Venda' });
    options.push({ value: 5, label: 'Componentes da Venda' });
    options.push({ value: 7, label: 'Lista de Alumínios da Venda' });
    if (sessaolocal.empresa_id == 203){
      options.push({ value: 8, label: 'Contrato da venda' });
    }
      
  }
  options.push({ value: 4, label: 'Venda/Propostas por Período' });

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value, downloadArquivo, comMedidas, comValores);
  };

  const handleChange = (event) => {
    setValue((event.target).value);
  };


  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      onClose={handleCancel}
      {...other}
    >
      <DialogTitle>{title || 'Opções'}</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => (
            <FormControlLabel
              value={option?.value}
              key={option}
              control={<Radio />}
              label={option?.label}
              disabled={option?.disabled ? option?.disabled : false}
            />
          ))}
        </RadioGroup>

      </DialogContent>
      {
        ((parseInt(value, 10) === 0) || (parseInt(value, 10) === 1)) &&
        <>
          <Box ml={2} display='flex'>
            <FormControlLabel control={
              <Checkbox
                checked={downloadArquivo}
                onChange={(e) => setDownloadArquivo(e.target.checked)}
              />
            }
              label='Download do Arquivo'
            />
            <Icon sx={{ mt: 1 }} color='info'>cloud_download</Icon>
          </Box>
          <Box ml={2} display='flex'>
            <FormControlLabel control={
              <Checkbox
                checked={comMedidas}
                onChange={(e) => setComMedidas(e.target.checked)}
              />
            }
              label='Com Medidas'
            />
            <FormControlLabel control={
              <Checkbox
                checked={comValores}
                onChange={(e) => setComValores(e.target.checked)}
              />
            }
              label='Com Valores'
            />
          </Box>
        </>

      }
      <DialogActions>
        {/* <Button autoFocus onClick={handleCancel}>
          Cancelar
        </Button>
        <Button onClick={handleOk}>Ok</Button> */}

        <MDButton onClick={handleCancel} variant="outlined" color="secondary">
          Cancelar
        </MDButton>
        <MDButton onClick={handleOk} variant="gradient" color='info' sx={{ height: "100%" }}>
          Confirmar
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

