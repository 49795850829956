import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDButton from 'components/MDButton';
import { Divider, Typography } from '@mui/material';
import FormField from 'componentes/FormField';

export default function DialogoDesconto(props) {

  const { open, handleClose, aplicaDesconto, valorAtual } = props;
  const [valorDesconto, setValorDesconto] = React.useState('0');

  const handleAplicar = () => {
    aplicaDesconto(valorDesconto);
  };

  React.useEffect(() => {
    setValorDesconto(valorAtual);
  }, [valorAtual])

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography variant='title' fontWeight="bold">Desconto na Venda R$</Typography>
          <Divider
            sx={{ bgcolor: (theme) => theme.palette.divider }}
            style={{
              marginTop: 20,
              border: "none",
              height: 2,
              margin: 0,
            }}
          />
        </DialogTitle>
        <DialogContent>
          <FormField
            autoFocus
            margin="dense"
            id="name"
            label="Valor do Desconto"
            type="number"
            fullWidth
            variant="standard"
            value={valorDesconto}
            onChange={(e) => setValorDesconto(e.target.value)}
            inputProps={{
              onKeyPress: (e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleAplicar();
                }
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Cancelar</Button> */}
          {/* <Button onClick={handleAplicar}>Aplicar</Button> */}
          <MDButton variant="outlined" color="secondary" onClick={handleClose}>Cancelar</MDButton>
          <MDButton variant="gradient" color="info" onClick={handleAplicar}> Aplicar </MDButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
