import React, { useState, useEffect, useContext } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MessagesContext from "hooks/MessagesContext";
import { Grid, Icon, Tooltip, Divider, Menu, MenuItem, ListItemIcon } from "@mui/material";
import AuthContext from 'hooks/AuthContext';
import DialogoEditarTransferencia from '../Cadastro'
import DialogoConfirmaValidacao from 'componentes/DialogoConfirmaValidacao';
import { formatDate, formataValor } from "commons/utils";
import { Link } from "react-router-dom";


export default function DialogoDetalheTransferencia(props) {

    const { open, message, handleCancel, title, setCodigoAbrir, codigoAbrir, recarregaTabela, setRecarregaTabela } = props;


    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera, MensagemAlerta } = messageContext;
    const baseUrl = `${rotafechada()}transferencia_banco`;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();


    //Dados Transferencia
    const [id, setId] = useState(0)
    const [contaOrigem, setContaOrigem] = useState('')
    const [contaDestino, setContaDestino] = useState('')
    const [valor, setValor] = useState(0)
    const [data, setData] = useState('')
    const [descricao, setDescricao] = useState('')

    const [editarTransferenciaOpen, setEditarTransferenciaOpen] = useState({ visible: false });
    const [codigoCarregado, setCodigoCarregado] = useState(0);
    const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });

    const validaRotaPermissao = async (operacao = '', silencioso = false) => {
        if ((operacao !== 'inserir') &&
            (operacao !== 'editar') &&
            (operacao !== 'excluir') &&
            (operacao !== 'listar')) {
            MensagemErro('Escolha a operação que deseja validar a permissão!');
            return false;
        }

        //validando a permissao
        try {
            await api.get(`${rotafechada()}transferencia_banco/permissao/${operacao}`);
            return true;
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;

            if (silencioso === false) {
                MensagemErro(`Erro: ${msg}`);
            }
            return false;
        }
    }


    const buscaDados = async () => {

        //validar para ver se veio id da transferencia
        if (!parseInt(codigoAbrir, 10) > 0)
            return;

        try {
            MostraTelaEspera()
            const retorno = await api.get(`${rotafechada()}transferencia_banco?id=${codigoAbrir}`);
            const { data, count } = retorno.data;

            //console.log('DADOS Transferencia:', data)

            if (data.length == 0)
                return;

            //Conta
            setId(data[0].id)
            setContaOrigem(data[0].Origem.nome)
            setContaDestino(data[0].Destino.nome)
            setValor(data[0].valor)
            setData(formatDate(new Date(data[0].data), "DD/MM/YYYY"))
            setDescricao(data[0].historico)

            FechaTelaEspera()
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleClickEditarTransferencia = (id) => {
        setCodigoCarregado(id);
        setEditarTransferenciaOpen({ ...editarTransferenciaOpen, visible: true });
    }

    const handleExcluir = async () => {
        setDialogoOpen({ ...dialogoOpen, visible: false });

        try {
            await api.delete(`${baseUrl}/full/${dialogoOpen.id || 0}`);
            MensagemAviso('Excluído com sucesso!');
            handleCancel()
            setCodigoCarregado('');
            setRecarregaTabela(!recarregaTabela);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleClickExcluir = async (id) => {

        if (!(await validaRotaPermissao('excluir') === true)) {
            return;
        }

        setDialogoOpen({ ...dialogoOpen, visible: true, id });
    }

    const limpaImputs = () => {

        setCodigoAbrir('');
    }

    useEffect(() => {
        if (open == true) {
            buscaDados();
        }

    }, [open, recarregaTabela])

    return (
        <>

            <DialogoConfirmaValidacao
                open={dialogoOpen.visible}
                title={'Confirma Exclusão'}
                color={'error'}
                message={'Certeza que deseja excluir? '}
                validacao={'Concordo com a exclusão desta transferência.'}
                handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
                handleConfirm={handleExcluir}
            />

            <DialogoEditarTransferencia
                setCodigoAbrir={setCodigoCarregado}
                codigoAbrir={codigoCarregado}
                recarregaTabela={recarregaTabela}
                setRecarregaTabela={setRecarregaTabela}
                title='Editar transferência entre contas'
                open={editarTransferenciaOpen.visible}
                handleCancel={() => {
                    setEditarTransferenciaOpen({ ...editarTransferenciaOpen, visible: false })
                    handleCancel()

                }}
            />
            <Dialog
                open={open}
                onClose={() => {
                    handleCancel()
                    limpaImputs()
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullScreen
            >
                <DialogTitle id="alert-dialog-title" sx={{ padding: '5px' }}>{/*HEADER*/}
                    <MDBox width="100%">
                        <MDBox display="flex" justifyContent="space-between">
                            <MDBox display="flex" justifyContent="space-start" flexDirection='column'>
                                <MDTypography fontWeight='bold' ml={5} mt={1} variant='h4'>{'Visualizar transferência entre contas'}</MDTypography>
                            </MDBox>

                            <Tooltip title="Fechar (Esc)">
                                <MDButton size="large" sx={{ padding: 0 }} onClick={() => {
                                    handleCancel()
                                    limpaImputs()
                                }}>
                                    <Icon>close</Icon>
                                </MDButton>

                            </Tooltip>
                        </MDBox>
                    </MDBox>
                </DialogTitle>

                <DialogContent dividers sx={{ backgroundColor: "#f1f4f9", borderColor: '#7bb5e4' }}>
                    <DialogContentText id="alert-dialog-description">

                        <MDBox ml={2} mr={2} pb={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/* CONTA */}
                            <Grid container spacing={2} mt={1} pl={2} pr={2}>

                                <Grid item xs={12} sm={12}> {/* TITULO*/}
                                    <MDTypography variant='subtitle2' fontWeight="bold">{'Informações da transferência'}</MDTypography>
                                    <Divider
                                        sx={{ bgcolor: (theme) => theme.palette.divider }}
                                        style={{
                                            marginTop: 5,
                                            marginBottom: 10,
                                            border: "none",
                                            height: 2,
                                            margin: 0,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* Conta de origem */}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Conta de origem'}</MDTypography>
                                        <MDTypography variant='caption' >{contaOrigem}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* Conta de destino */}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Conta de destino'}</MDTypography>
                                        <MDTypography variant='caption' >{contaDestino}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={8} >{/*Descrição*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Descrição'}</MDTypography>
                                        <MDTypography variant='caption' >{descricao}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* VALOR */}
                                    <MDTypography variant='caption' fontWeight="bold">{'Valor'}</MDTypography>
                                    <MDTypography fontWeight="bold" >{formataValor(valor)}</MDTypography>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/*Data da transferência*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Data da transferência'}</MDTypography>
                                        <MDTypography variant='caption' >{data}</MDTypography>
                                    </MDBox>
                                </Grid>

                            </Grid>

                        </MDBox>
                    </DialogContentText>
                </DialogContent>

                <DialogActions sx={{ padding: '7px', margin: 0.5 }}> {/*FOOTER*/}
                    {/* <MDBox width="100%"> */}

                    <MDBox width="100%" display="flex" justifyContent="space-between">
                        <MDBox width="100%" display="flex" justifyContent="flex">
                            <MDButton onClick={() => {
                                handleCancel()
                                limpaImputs()
                            }} variant="gradient" type="button" color="secondary">
                                voltar
                            </MDButton>
                        </MDBox>

                        <MDBox width="18%" display="flex" justifyContent="flex">
                            <MDBox width="50%" display="flex" justifyContent="flex-start">
                                <MDButton
                                    onClick={() => handleClickExcluir(codigoAbrir)}
                                    variant="outlined"
                                    //type="submit"
                                    type="button"
                                    color="error">
                                    {'Excluir'}
                                </MDButton>
                            </MDBox>
                            <MDBox width="50%" display="flex" justifyContent="flex-end">
                                <MDButton
                                    onClick={() => handleClickEditarTransferencia(codigoAbrir)}
                                    variant="gradient"
                                    type="submit"
                                    //type="button" 
                                    color="info">
                                    {'Editar'}
                                </MDButton>
                            </MDBox>

                        </MDBox>

                    </MDBox>
                    {/* </MDBox> */}
                </DialogActions>
            </Dialog>
        </>
    );
}