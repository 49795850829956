import { Autocomplete, Grid, Typography } from '@mui/material';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import React from 'react';
import { Controller } from 'react-hook-form';
import PaletteIcon from '@mui/icons-material/Palette';

import DadosPrecos from './precos';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';


const Grades = (props) => {
  const { control, setValue, getValues, itemCor, indiceVetor, opcoesCor, produtos, register } = props;

  const handleBlurCusto = () => {

    let custo = getValues(`grades[${indiceVetor}].valor_custo`);
    custo = `${custo}`.replace(',', '.');
    if (!parseFloat(custo) > 0) {
      custo = 0;
    }

    produtos?.grades[indiceVetor]?.precos.map((item, keypreco) => {

      let margem = getValues(`grades[${indiceVetor}].precos[${keypreco}].percentual_margem`);
      margem = `${margem}`.replace(',', '.');
      if (!parseFloat(margem) > 0) {
        margem = 0;
      }

      const valorsugerido = parseFloat(custo) + (parseFloat(custo) * parseFloat(margem)) / 100;
      setValue(`grades[${indiceVetor}].precos[${keypreco}].valor_sugerido`, valorsugerido);
    });
  }

  return (

    <MDBox p={2} bgColor='#FBEEE6' mb={1}>
      <Typography variant='h6' mb={2}>Cores <PaletteIcon sx={{ color: '#DE3163' }} /> [{indiceVetor + 1}]</Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={2}>{/*id*/}
          <Controller
            name={`grades[${indiceVetor}].id`}
            control={props.control}
            defaultValue=''
            render={({ field }) =>
              <FormField
                {...field}
                autoComplete={false}
                type='text'
                label='Id'
                rows={1}
                multiline={false}
                required={false}
                disabled={true}
                inputProps={{
                  disabled: true
                }}
              />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>{/*Cor*/}
          <Controller
            name={`grades[${indiceVetor}].cor_id`}
            control={control}
            defaultValue={{ value: -1 }}
            render={({ field: { onChange, ...props } }) => (
              <AutoCompleteStyled
                options={opcoesCor}
                disableClearable //Retira o X (clear)
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <FormField required {...params} label='Cor' />}
                onChange={(_, data) => {
                  return onChange(data);
                }}
                disabled={false}
                {...props}

              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>{/*Custo*/}
          <Controller
            name={`grades[${indiceVetor}].valor_custo`}
            control={props.control}
            defaultValue=''
            render={({ field }) =>
              <FormField
                {...field}
                autoComplete={false}
                type='text'
                label='Valor Custo'
                rows={1}
                multiline={false}
                required={true}
                disabled={false}
                inputProps={{
                  onKeyUp: (e) => {
                  },
                  onBlur: (e) => {
                    e.preventDefault();
                    handleBlurCusto();
                  },
                }}

              />}
          />
        </Grid>
        <Grid item xs={12} sm={3}>{/*Ativo*/}
          <Controller
            name={`grades[${indiceVetor}].ativo`}
            control={control}
            defaultValue={{ value: 'N' }}
            render={({ field: { onChange, ...props } }) => (
              <AutoCompleteStyled
                options={[
                  { value: 'S', label: 'Sim' },
                  { value: 'N', label: 'Não' },
                ]}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <FormField required {...params} label='Ativo' />}
                onChange={(_, data) => {
                  return onChange(data);
                }}
                disabled={false}
                {...props}
              />
            )}
          />
        </Grid>
      </Grid>
      {
        itemCor.precos?.map((itempreco, key) => {
          return (
            <DadosPrecos
              control={control}
              setValue={setValue}
              indiceVetor={key}
              key={itempreco.id || key}
              indiceCor={indiceVetor}
              getValues={getValues}
              register={register}
            />
          )
        })
      }

    </MDBox>
  )
}

export default Grades;