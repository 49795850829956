import { Autocomplete, Button, Checkbox, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import api from 'commons/api';
import { rotafechada } from 'commons/urlconf';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import MessagesContext from 'hooks/MessagesContext';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Controller, useWatch } from 'react-hook-form';
import MDInput from 'components/MDInput';
import DoneIcon from '@mui/icons-material/Done';


const TabelaProdutosopcional = (props) => {

    const { objFull, control, setValue } = props;
    const [checkAll, setCheckAll] = useState(true);

    useEffect(() => {

        objFull?.produtos?.map((item, index) => {
            if (item?.opcional === 'S') {
                setValue(`projetos.produtos[${index}].incluido`, checkAll);
            }
        });
    }, [checkAll])

    return (

        <Grid container>
            <Grid item xs={12} sm={12} mb={2}>
                <Typography component="div" variant='h6'>Produtos Opcionais</Typography>
            </Grid>

            <Grid item xs={12} sm={12} >
                <TableContainer>
                    <Table size='small'>
                        <MDBox component="thead" bgColor='grey-200' >
                            <TableRow>
                                <TableCell>Tipo</TableCell>
                                <TableCell>Nome</TableCell>
                                <TableCell>Observação</TableCell>
                                <TableCell>Incluído
                                    <Checkbox
                                        checked={checkAll}
                                        onChange={(e) => setCheckAll(e.target.checked)}
                                    />
                                </TableCell>
                            </TableRow>
                        </MDBox>
                        {
                            objFull.produtos.map((item, index) =>
                                item?.opcional === 'S' ?
                                    <TableBody>
                                        <TableRow >
                                            <TableCell>{item?.Produto?.Tipos_Produto.nome || ''}</TableCell>
                                            <TableCell>{item?.Produto?.nome}</TableCell>
                                            <TableCell>{item?.observacao}</TableCell>
                                            <TableCell>
                                                <Controller
                                                    name={`projetos.produtos[${index}].incluido`}
                                                    control={control}
                                                    render={({ field: { value, ...otherprops } }) => (
                                                        <Checkbox
                                                            {...otherprops}
                                                            disabled={item?.opcional === 'S' ? false : true}
                                                            checked={value ? (value === true ? true : false) : false}
                                                            onChange={(e) => setValue(`projetos.produtos[${index}].incluido`, e.target.checked)}
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                    : false
                            )
                        }
                    </Table>
                </TableContainer>
            </Grid>
        </Grid >
    )
}


const TabelaServicosopcional = (props) => {

    const { objFull, control, setValue } = props;
    const [checkAll, setCheckAll] = useState(true);

    useEffect(() => {

        objFull?.servicos?.map((item, index) => {
            if (item?.opcional === 'S') {
                setValue(`projetos.servicos[${index}].incluido`, checkAll);
            }
        });
    }, [checkAll])

    return (

        <Grid container>
            <Grid item xs={12} sm={12} mb={2}>
                <Typography component="div" variant='h6'>Serviços Opcionais</Typography>
            </Grid>

            <Grid item xs={12} sm={12} >
                <TableContainer>
                    <Table size='small'>
                        <MDBox component="thead" bgColor='grey-200' >
                            <TableRow>
                                <TableCell>Nome</TableCell>
                                <TableCell>Observação</TableCell>
                                <TableCell>
                                    Incluído
                                    <Checkbox
                                        checked={checkAll}
                                        onChange={(e) => setCheckAll(e.target.checked)}
                                    /></TableCell>
                            </TableRow>
                        </MDBox>
                        {
                            objFull.servicos.map((item, index) =>
                                item?.opcional === 'S' ?
                                    <TableBody>
                                        <TableRow >
                                            <TableCell>{item?.Servico?.nome}</TableCell>
                                            <TableCell>{item?.observacao}</TableCell>
                                            <TableCell>
                                                <Controller
                                                    name={`projetos.servicos[${index}].incluido`}
                                                    control={control}
                                                    render={({ field: { value, ...otherprops } }) => (
                                                        <Checkbox
                                                            {...otherprops}
                                                            disabled={item?.opcional === 'S' ? false : true}
                                                            checked={value ? (value === true ? true : false) : false}
                                                            onChange={(e) => setValue(`projetos.servicos[${index}].incluido`, e.target.checked)}
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                    : false
                            )
                        }
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}

const ProdutosServicosOpcionaisProjetos = (props) => {


    const { control, setValue, getValues, vendaAtual, handleSubmit,
        etapaAtual, setEtapaAtual, objFull, proximaEtapa, desenhaCabecalhoPadrao } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;

    function gravarTeste(data) {
        console.log('objfull-original:', objFull);
        console.log('obj-RHF:', data);
    }

    const validaProximaEtapa = () => {
        proximaEtapa();
    }

    const etapaAnterior = () => {
        setEtapaAtual(etapaAtual - 1);
    }

    const verificaSeTemProdutosouServicosOpcionais = async () => {
        if ((!(objFull.produtos?.filter((item) => item.opcional === 'S')?.length > 0)) &&
            (!(objFull.servicos?.filter((item) => item.opcional === 'S')?.length > 0))) {
            validaProximaEtapa();
        }
    }

    useEffect(() => {
        verificaSeTemProdutosouServicosOpcionais();
    }, [])


    return (
        <>
            {desenhaCabecalhoPadrao('Opcionais - ')}

            <MDBox width="100%" display="flex" mb={4}>
                <MDTypography variant='button'>Selecione os produtos ou serviços que são opcionais</MDTypography>
            </MDBox>
            <MDBox width="100%" display="flex" mt={2}>
                {
                    (objFull.produtos?.filter((item) => item.opcional === 'S')?.length > 0) &&
                    <TabelaProdutosopcional
                        objFull={objFull}
                        control={control}
                        setValue={setValue}
                    />
                }
            </MDBox>
            <MDBox width="100%" display="flex" mt={2}>
                {
                    (objFull.servicos?.filter((item) => item.opcional === 'S')?.length > 0) &&
                    <TabelaServicosopcional
                        objFull={objFull}
                        control={control}
                        setValue={setValue}
                    />
                }
            </MDBox>

            <MDBox width="100%" display="flex" justifyContent="flex-end" mt={2}>
                <MDButton variant="gradient" color="info" onClick={etapaAnterior} sx={{ ml: 2 }}>Retornar</MDButton>
                {
                    (props.parametrosVenda.mostra_botao_obj || false) &&
                    <MDButton variant="gradient" color="warning" onClick={() => handleSubmit(gravarTeste)()} sx={{ ml: 2 }}>Mostra OBJ</MDButton>
                }
                <MDButton variant="gradient" color="info" onClick={validaProximaEtapa} sx={{ ml: 2 }}>Avançar</MDButton>
            </MDBox>
        </>
    )
}

export default ProdutosServicosOpcionaisProjetos;
