import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import MDBox from 'components/MDBox';
import { Icon, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import MDButton from 'components/MDButton';
import MostraImagem from 'componentes/MostraImagem';


/*
colunas = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Id'
  },
  {
    id: 'nome',
    numeric: false,
    disablePadding: true,
    label: 'Nome'
  },
  {
    id: 'ativo',
    numeric: false,
    disablePadding: true,
    label: 'Ativo',
    disableOrder: false
  },
  {
    id: 'acoes',
    numeric: false,
    disablePadding: true,
    label: 'Ações',
    disableOrder: true
  },
]

linhas = [
  {
    id:10,
    nome:'XXX',
    ativo: 'S',
    acoes:<button/>
  }
]
*/



function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort, headCells } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (

    <MDBox component="thead" bgColor='grey-200' >
      {/* <TableHead> */}

      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >

            {

              headCell.disableOrder === true ?

                <Typography
                  variant="h6"
                  component="div"
                >
                  {headCell.label}
                </Typography>
                :

                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  <Typography
                    variant="h6"
                    component="div"
                  >
                    {headCell.label}
                  </Typography>

                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
            }
          </TableCell>
        ))}
      </TableRow>
    </MDBox>


  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,

  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


function TabelaPrecos(props) {
  const { row, valor_custo } = props;

  const calcula_preco_sugerido = (percentual_margem) => {
    const valor_custo_temp = parseFloat(props.valor_custo);
    const percentual_margem_temp = parseFloat(percentual_margem);
    const valor_sugerido_temp = valor_custo_temp + ((valor_custo_temp * percentual_margem_temp) / 100);
    return valor_sugerido_temp;
  }

  return (
    <Table size="small" aria-label="purchases">
      <MDBox component="thead" bgColor='grey-100' >
        <TableRow>
          <TableCell>Tabela</TableCell>
          <TableCell>Margem(%)</TableCell>
          <TableCell>Sugerido(R$)</TableCell>
          <TableCell>Venda(R$)</TableCell>
        </TableRow>
      </MDBox>
      <TableBody>
        {row.Produto_Precos?.map((precoRow) => (
          <TableRow key={precoRow.id}>
            <TableCell>{precoRow.Tabelas_Preco.nome}</TableCell>
            <TableCell>{precoRow.percentual_margem}</TableCell>
            <TableCell>{calcula_preco_sugerido(precoRow.percentual_margem)}</TableCell>
            <TableCell>{precoRow.valor_venda}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>

  )
}

function TabelaGradeCor(props) {
  const { row } = props;

  return (
    <Table size="small" aria-label="purchases">
      <MDBox component="thead" bgColor='grey-100' >
        <TableRow>
          <TableCell>Cor</TableCell>
          <TableCell>Ativo</TableCell>
          <TableCell>Custo</TableCell>
          <TableCell>Preços</TableCell>
        </TableRow>
      </MDBox>
      <TableBody>
        {row.Produto_Grades?.map((gradeRow) => (
          <TableRow key={gradeRow.id}>
            <TableCell>{gradeRow.Core.nome}</TableCell>
            <TableCell>{gradeRow.ativo}</TableCell>
            <TableCell>{gradeRow.valor_custo}</TableCell>
            <TableCell><TabelaPrecos row={gradeRow} valor_custo={gradeRow.valor_custo} /></TableCell>

          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

function Row(props) {
  const { row, headCells } = props;
  const [open, setOpen] = React.useState(false);
  const [openZoomImg, setOpenZoomImg] = React.useState({
    open: false,
    img: ''
  });

  const handleClickAbrirImg = async (imagemstr) => {
    const dados = {
      open: true,
      img: imagemstr
    }
    setOpenZoomImg(dados);
  }

  return (
    <React.Fragment>
      <MostraImagem
        open={openZoomImg.open}
        setOpen={setOpenZoomImg}
        img={openZoomImg.img}
        alt='Imagem do produto'
      />
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>

        {/* Imagem do Projeto */}
        <TableCell align={'left'} padding={'normal'}>
          <div>
            {
              row.imagem ?
                <img src={`data:image/png[jpg];base64,${row.imagem}`} alt="Imagem do Produto" onClick={() => handleClickAbrirImg(`data:image/png[jpg];base64,${row.imagem}`)} style={{ maxWidth: 150, maxHeight: 100, cursor: "zoom-in" }} />
                :
                false
            }
          </div>
        </TableCell>


        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {
          headCells.map((item, index) => {
            return (
              ((item.id !== 'collapse') && (item.id !== 'botoes') && (item.id !== 'imagem')) ?
                <TableCell
                  align={item.numeric ? 'right' : 'left'}
                  padding={item.disablePadding ? 'none' : 'normal'}
                  key={`tc${index}`}
                >
                  {row[item.id]}
                </TableCell>
                : false
            )
          })
        }
        <TableCell>
          <MDButton size="medium" onClick={() => props.abrirRegistro(row.id)} iconOnly >
            <Icon fontSize="medium" color="success">edit_icon</Icon>
          </MDButton>
          <MDButton size="medium" onClick={() => props.handleClickExcluir(row.id)} iconOnly >
            <Icon fontSize="medium" color="error">delete_forever</Icon>
          </MDButton>
        </TableCell>
      </TableRow>
      {/* LInha que desenhara a tabela de cores e precos */}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <TabelaGradeCor row={row} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment >
  );
}

function TabelaProdutoGradePreco(props) {

  const { linhas: rows, colunas: headCells, countAll, page, setPage, rowsPerPage, setRowsPerPage, order, setOrder, orderBy, setOrderBy } = props;

  const handleRequestSort = (event, property) => {
    //property é o ID da coluna que sera usada
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size='small'
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}

              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {rows.map((row) => (
                <Row key={row.id} row={row} headCells={headCells}
                  handleClickExcluir={props.handleClickExcluir}
                  abrirRegistro={props.abrirRegistro}
                />
              ))}
            </TableBody>

          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={countAll}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage='Linhas por Página'
        />
      </Paper>
    </Box>
  );
}

export default React.memo(TabelaProdutoGradePreco);