import React, { useContext, useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MessagesContext from 'hooks/MessagesContext';
import AuthContext from "hooks/AuthContext";
import { useForm, useWatch } from 'react-hook-form';
import MDButton from 'components/MDButton';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import { copiaProfundaObjetos } from 'commons/utils';
import Cabecalho from './cabecalho';
import Grades from './grades';
import Tabela from './tabela';
import DialogoConfirma from 'componentes/DialogoConfirma';
import { Collapse, Grid, Icon, IconButton, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ProjetosProduto from './projetos';
import DialogoOpcoes from 'componentes/DialogoOpcoes';
import FiltroSubstituicaoProdutoProjeto from './filtrosubstituicaoprodutoprojeto';
import { formataFloat } from 'commons/utils';

function Cadastro(props) {

  const navigate = useNavigate();

  const { produtoAtual, setProdutoAtual, setAba } = props;
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const { handleSubmit, control, setValue, getValues, register } = useForm({});
  const [openCollapseProjeto, setOpenCollapseProjeto] = useState(false);
  const [openFiltroSubstituicaoProdutoProjeto, setOpenFiltroSubstituicaoProdutoProjeto] = useState({ open: false })

  const [dialogoCopiaPrecos, setDialogoCopiaPrecos] = useState({
    open: false,
    produto_grade_id: ''
  })

  const objModeloPrecos = {
    id: '',
    nome_tabela: '',
    tabela_preco_id: '',
    percentual_margem: '',
    valor_sugerido: '',
    valor_venda: '',
    ativo: true
  }

  const objModeloGrades =
  {
    id: '',
    cor_id: '',
    valor_custo: '',
  }

  const objModeloProduto = {
    id: '',
    grupo_produto_id: { value: -1 },
    tipo_produto_id: { value: -1 },
    unidade_medida_id: { value: -1 },
    referencia: '',
    nome: '',
    ativo: { value: -1 },
    nome_imagem: '',
    observacao: '',
    peso: '',
    tamanho_barra: ''
  }

  const [produtos, setProdutos] = useState({});
  const [opcoesAtivo, setOpcoesAtivo] = useState([
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'S', label: 'Sim' },
    { value: 'N', label: 'Não' },
  ]);
  const [opcoesTipo, setOpcoesTipo] = useState([]);
  const [opcoesGrupo, setOpcoesGrupo] = useState([]);
  const [opcoesCor, setOpcoesCor] = useState([]);
  const [opcoesUnidadeMedida, setOpcoesUnidadeMedida] = useState([]);
  const [recarregaTabela, setRecarregaTabela] = useState(false);
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: '' });
  const opcaoDefaultGrupoProduto = [{ id: -1, value: -1, label: '<Selecione um Grupo>', tipo_produto_id: -1 }];

  const [opcoes, setOpcoes] = useState({
    open: false,
    items: [{}]
  });

  const onSubmit = (data) => {
    handleClickGravar(data);
  }

  const limpaInputs = () => {
    //console.log('limpainputs');
    Object.keys(objModeloProduto).forEach((item) => {
      setValue(`${item}`, objModeloProduto[item]);
    })

    produtos.grades?.forEach((itemgrade, indexgrade) => {
      Object.keys(objModeloGrades).forEach((item) => {
        setValue(`grades[${indexgrade}].${item}`, objModeloGrades[item])
      })

      itemgrade.precos?.forEach((itempreco, indexpreco) => {
        Object.keys(objModeloPrecos).forEach((item) => {
          setValue(`grades[${indexgrade}].precos[${indexpreco}].${item}`, objModeloPrecos[item])
        })
      })
    })
  }

  const validaCampos = (data) => {

    let camposObrigatorios = ``;

    //console.log('função validaCampos', data);

    if (!data.nome) {
      camposObrigatorios = `${camposObrigatorios}/Nome`
    }

    if (!data.ativo) {
      camposObrigatorios = `${camposObrigatorios}/Ativo`
    }

    if (!(parseInt(data.tipo_produto_id, 10) > 0)) {
      camposObrigatorios = `${camposObrigatorios}/Tipo`
    }

    if (!(parseInt(data.grupo_produto_id, 10) > 0)) {
      camposObrigatorios = `${camposObrigatorios}/Grupo`
    }
    if (!(parseInt(data.unidade_medida_id, 10) > 0)) {
      camposObrigatorios = `${camposObrigatorios}/Unidade de Medida`
    }


    data.grades?.forEach((itemGrade, key) => {
      if (!(parseInt(itemGrade.cor_id, 10) > 0)) {
        camposObrigatorios = `${camposObrigatorios}/Cor`
      } else {
        //verificando se essa cor ja nao esta em outro item do vetor
        data.grades.forEach((itemgrade2, key2) => {
          if ((itemGrade.cor_id === itemgrade2.cor_id) && (key !== key2)) {
            camposObrigatorios = `${camposObrigatorios}/Cor do item ${key + 1} duplicada no item ${key2 + 1}`
          }
        })
      }

      if (!(parseFloat(itemGrade.valor_custo) >= 0)) {
        camposObrigatorios = `${camposObrigatorios}/Valor de custo`
      }
      itemGrade.precos?.forEach((itemPreco) => {
        if (!(parseInt(itemPreco.tabela_preco_id, 10) > 0)) {
          camposObrigatorios = `${camposObrigatorios}/Tabela de Preço`
        }
        //if (itemPreco.ativo == "S") {
          if (!(parseFloat(itemPreco.percentual_margem) >= 0)) {
            itemPreco.percentual_margem = 0
            //camposObrigatorios = `${camposObrigatorios}/Margem`
          }
        //}
        //if (itemPreco.ativo == "S") {
          if (!(parseFloat(itemPreco.valor_venda) >= 0)) {
            itemPreco.valor_venda = 0
            //camposObrigatorios = `${camposObrigatorios}/Valor de Venda`
          }
        //}
      })
    })

    if (camposObrigatorios !== '') {
      throw new Error(`Campos obrigatórios: ${camposObrigatorios}`)
    }
  }

  const handleClickGravar = async (data) => {

    //debugger;
    //tratando os campos autocomplete
    data.ativo = data.ativo?.value ? data.ativo.value : '';
    if (data.ativo !== 'N') {
      data.ativo = 'S'
    }
    data.tipo_produto_id = data.tipo_produto_id?.value ? data.tipo_produto_id.value : '';
    data.grupo_produto_id = data.grupo_produto_id?.value ? data.grupo_produto_id.value : '';
    data.unidade_medida_id = data.unidade_medida_id?.value ? data.unidade_medida_id.value : '';

    const gradestemp = [];

    if (produtos.grades?.length > 0) {
      //vou mapear a grade de cor pelo state que controla "visualmente" os componentes em tela
      produtos.grades?.map((_, index) => {

        const itemcor = data.grades[index];
        itemcor.cor_id = itemcor.cor_id?.value ? itemcor.cor_id.value : '';
        itemcor.ativo = itemcor.ativo?.value ? itemcor.ativo.value : '';
        itemcor.valor_custo = `${itemcor.valor_custo}`.replace(',', '.');
        if (itemcor.ativo !== 'N') {
          itemcor.ativo = 'S'
        }

        itemcor.precos?.forEach((itempreco) => {
          if (itempreco.ativo === false) {
            itempreco.ativo = 'N'
          }
          else itempreco.ativo = 'S';

          itempreco.percentual_margem = `${itempreco.percentual_margem}`.replace(',', '.');
          itempreco.valor_venda = `${itempreco.valor_venda}`.replace(',', '.');

          delete itempreco.valor_sugerido;
        })

        gradestemp.push(copiaProfundaObjetos(itemcor));
      });
      delete data.grades;
      data.grades = gradestemp;
    }
    else {
      delete data.grades;
    }

    try {
      validaCampos(data);
    }
    catch (error) {
      MensagemErro(`${error.message}`);
      return;
    }

    try {

      const dados = { ...data, empresa_id: sessaolocal.empresa_id || null };
      delete dados.id;

      //console.log('dados a serem enviados para gravar:');
      //console.log(dados);

      let retorno;
      let codigoProduto = '';
      if (dados.peso === '') {
        dados.peso = null
      }
      if (dados.tamanho_barra === '') {
        dados.tamanho_barra = null
      }

      if (parseInt(produtoAtual, 10) > 0) {
        //na alteracao nao envia o nome_imagem
        delete dados.nome_imagem;
        retorno = await api.put(`${rotafechada()}produto_grade_preco/${produtoAtual}`, dados);
        codigoProduto = produtoAtual;
        setRecarregaTabela(!recarregaTabela);
      }
      else {
        //console.log('nome_imagem:', dados.nome_imagem);
        retorno = await api.post(`${rotafechada()}produto_grade_preco`, dados);
        codigoProduto = retorno.data.data.id;
        navigate(`/produtos/produto/${codigoProduto}`, { replace: true });
      }

      MensagemAviso(`Gravado com sucesso! Produto: ${codigoProduto}`);

      //apagando a cor no vetor
      const produtoTemp = { ...produtos };
      delete produtoTemp.grades;
      setProdutos(produtoTemp);

      //se for um novo produto entao "recarrego" a pagina
      if (codigoProduto !== produtoAtual) {
        setProdutoAtual(codigoProduto);
      }
    }
    catch (error) {
      console.log(error);
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }
  /******************************************************************************************************* */
  const buscaDatasets = async () => {
    //console.log('buscando cadastros...');

    let url = '';
    let retorno;
    let opcoesGrupoProdutoTemp = [];
    let opcoesTipoProdutoTemp = [];
    let opcoesUnidadeMedidaTemp = [];
    let opcoesCorTemp = [];

    try {
      url = `${rotafechada()}tipos_produtos?limit=-1`;
      retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      opcoesTipoProdutoTemp = [{ id: -1, value: -1, label: '<Selecione um Tipo>' }];

      data.map((item) => {
        opcoesTipoProdutoTemp.push({ id: item.id, value: item.id, label: item.nome })
      })

      setOpcoesTipo(opcoesTipoProdutoTemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }

    try {
      url = `${rotafechada()}cores?limit=-1`;
      retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      opcoesCorTemp = [{ id: -1, value: -1, label: '<Selecione uma Cor>' }];

      data.map((item) => {
        opcoesCorTemp.push({ id: item.id, value: item.id, label: item.nome })
      })

      setOpcoesCor(opcoesCorTemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }

    try {
      url = `${rotafechada()}unidades_medidas?limit=-1`;
      retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      opcoesUnidadeMedidaTemp = [{ id: -1, value: -1, label: '<Selecione uma Unidade>' }];

      data.map((item) => {
        opcoesUnidadeMedidaTemp.push({ id: item.id, value: item.id, label: item.nome })
      })

      setOpcoesUnidadeMedida(opcoesUnidadeMedidaTemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const abrirRegistroCor = async (id) => {
    await abrirProduto(id);
  }

  const excluirRegistroCor = async () => {
    setDialogoOpen({ ...dialogoOpen, visible: false });

    try {
      await api.delete(`${rotafechada()}produto_grade_preco/${dialogoOpen.id || 0}`);
      MensagemAviso('Excluído com sucesso!');
      const produtoTemp = { ...produtos };
      delete produtoTemp.grades;
      setProdutos(produtoTemp);
      setRecarregaTabela(!recarregaTabela);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickExcluirCor = async (id) => {

    // if (!(await validaRotaPermissao('excluir') === true)) {
    //   return;
    // }
    setDialogoOpen({ ...dialogoOpen, visible: true, id });
  }

  const handleClickNovaCor = async () => {

    //copia superficial (assim nao altera as posicoes atuais do "grades" no objeto)
    const produtosTemp = { ...produtos };
    const objgradesTemp = copiaProfundaObjetos(objModeloGrades);
    objgradesTemp.precos = [];
    if (!produtosTemp.grades) {
      produtosTemp.grades = [];
    }
    produtosTemp.grades.push(objgradesTemp);
    const indiceNovaGrade = produtosTemp.grades.length - 1;

    const opcoesTabelaPrecoTemp = await retornaTabelasPrecos();

    opcoesTabelaPrecoTemp.map((item, key) => {
      objgradesTemp.precos.push({
        id: '',
      })

      setValue(`grades[${indiceNovaGrade}].id`, '');
      setValue(`grades[${indiceNovaGrade}].cor_id`, { value: -1 });
      setValue(`grades[${indiceNovaGrade}].valor_custo`, '0');
      setValue(`grades[${indiceNovaGrade}].ativo`, { value: 'S', label: 'Sim' });

      setValue(`grades[${indiceNovaGrade}].precos[${key}].id`, '');
      setValue(`grades[${indiceNovaGrade}].precos[${key}].valor_sugerido`, '0');
      setValue(`grades[${indiceNovaGrade}].precos[${key}].valor_venda`, '0');
      setValue(`grades[${indiceNovaGrade}].precos[${key}].percentual_margem`, '0');
      setValue(`grades[${indiceNovaGrade}].precos[${key}].tabela_preco_id`, item.id);
      setValue(`grades[${indiceNovaGrade}].precos[${key}].nome_tabela`, item.label);
      setValue(`grades[${indiceNovaGrade}].precos[${key}].ativo`, true);
    })
    setProdutos(produtosTemp);
  }

  const retornaTabelasPrecos = async () => {
    try {
      const url = `${rotafechada()}tabelas_precos?limit=-1`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      const opcoesTabelaPrecoTemp = [];

      data.forEach((item) => {
        opcoesTabelaPrecoTemp.push({ id: item.id, value: item.id, label: item.nome });
      })

      return opcoesTabelaPrecoTemp;
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
      return [];
    }
  }

  const novoProduto = async () => {

    const produtoTemp = { ...produtos };
    delete produtoTemp.grades;
    produtoTemp.grades = [{ id: '', precos: [] }];

    limpaInputs();

    const tabelasTemp = await retornaTabelasPrecos();
    tabelasTemp.map((item, key) => {
      produtoTemp.grades[0].precos.push({ id: '' });
      setValue(`grades[0].precos[${key}].id`, '');
      setValue(`grades[0].precos[${key}].valor_sugerido`, '');
      setValue(`grades[0].precos[${key}].valor_venda`, '');
      setValue(`grades[0].precos[${key}].percentual_margem`, '');
      setValue(`grades[0].precos[${key}].ativo`, true);
      setValue(`grades[0].precos[${key}].tabela_preco_id`, item.id);
      setValue(`grades[0].precos[${key}].nome_tabela`, item.label);
    })
    setProdutos(produtoTemp);
    navigate(`/produtos/produto/0`, { replace: true });
  }

  const abrirProduto = async (produtoGradeId = 0) => {
    try {
      let url = `${rotafechada()}produto_grade_preco?id=${produtoAtual}`;
      if (parseInt(produtoGradeId, 10) > 0) {
        url = `${url}&produto_grade_id=${produtoGradeId}`
      }

      MostraTelaEspera('Aguarde...');
      const retorno = await api.get(encodeURI(url));
      FechaTelaEspera();
      const { data } = retorno.data;

      if (!data.length > 0) {
        MensagemErro(`Produto ${produtoAtual} não localizado`);
        return;
      }
      const produtoTemp = { ...produtos };
      limpaInputs();

      delete produtoTemp.grades;
      produtoTemp.id = data[0].id;

      //setando os valores do produto no hook-form
      setValue(`id`, data[0].id);
      setValue(`referencia`, data[0].referencia);
      setValue(`nome`, data[0].nome);
      setValue(`observacao`, data[0].observacao);
      setValue(`peso`, formataFloat(data[0].peso));
      setValue(`tamanho_barra`, formataFloat(data[0].tamanho_barra));
      setValue(`ativo`, { value: data[0].ativo === 'S' ? 'S' : 'N', label: data[0].ativo === 'S' ? 'Sim' : 'Não' });
      setValue(`tipo_produto_id`, { value: data[0].tipo_produto_id, label: data[0].Tipos_Produto.nome });
      setValue(`grupo_produto_id`, { value: data[0].grupo_produto_id, label: data[0].Grupos_Produto.nome, tipo_produto_id: data[0].Grupos_Produto.tipo_produto_id });
      setValue(`unidade_medida_id`, { value: data[0].unidade_medida_id, label: data[0].Unidades_Medida.nome });

      if (parseInt(produtoGradeId, 10) > 0) {
        const opcoesTabelaPrecoTemp = await retornaTabelasPrecos();

        data[0].Produto_Grades?.forEach((itemgrade) => {

          if (itemgrade.id === produtoGradeId) {

            produtoTemp.grades = [];
            produtoTemp.grades.push({ id: itemgrade.id, precos: [] });

            setValue(`grades[0].id`, itemgrade.id);
            setValue(`grades[0].cor_id`, { value: itemgrade.cor_id, label: itemgrade.Core?.nome });
            setValue(`grades[0].valor_custo`, parseFloat(itemgrade.valor_custo || 0).toFixed(4));
            setValue(`grades[0].ativo`, { value: itemgrade.ativo === 'S' ? 'S' : 'N', label: itemgrade.ativo === 'S' ? 'Sim' : 'Não' });

            //adicionando as tabelas de preços disponiveis
            opcoesTabelaPrecoTemp.forEach((itemTabelaPreco, keyTabelaPreco) => {
              produtoTemp.grades[0].precos.push({
                id: '',
              })
              setValue(`grades[0].precos[${keyTabelaPreco}].id`, '');
              setValue(`grades[0].precos[${keyTabelaPreco}].valor_sugerido`, '');
              setValue(`grades[0].precos[${keyTabelaPreco}].valor_venda`, '');
              setValue(`grades[0].precos[${keyTabelaPreco}].percentual_margem`, '');
              setValue(`grades[0].precos[${keyTabelaPreco}].tabela_preco_id`, itemTabelaPreco.id);
              setValue(`grades[0].precos[${keyTabelaPreco}].nome_tabela`, itemTabelaPreco.label);
              setValue(`grades[0].precos[${keyTabelaPreco}].ativo`, false);

              //procurando essa tabela de preço no retorno do back
              itemgrade.Produto_Precos?.forEach((itemGradePreco) => {
                if (itemGradePreco.tabela_preco_id === itemTabelaPreco.id) {
                  setValue(`grades[0].precos[${keyTabelaPreco}].id`, itemGradePreco.id);
                  setValue(`grades[0].precos[${keyTabelaPreco}].valor_venda`, parseFloat(itemGradePreco.valor_venda).toFixed(4));
                  setValue(`grades[0].precos[${keyTabelaPreco}].percentual_margem`, parseFloat(itemGradePreco.percentual_margem).toFixed(2));
                  setValue(`grades[0].precos[${keyTabelaPreco}].ativo`, itemGradePreco.ativo === 'S' ? true : false);

                  const valor_custo_temp = parseFloat(itemgrade.valor_custo);
                  const percentual_margem_temp = parseFloat(itemGradePreco.percentual_margem);
                  const valor_sugerido_temp = valor_custo_temp + ((valor_custo_temp * percentual_margem_temp) / 100);
                  setValue(`grades[0].precos[${keyTabelaPreco}].valor_sugerido`, parseFloat(valor_sugerido_temp).toFixed(2));
                }
              })
            })
          }
        })
      }
      setProdutos(produtoTemp);
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickExcluirItemCor = () => {

    let produtosTemp = { ...produtos };

    if (produtosTemp.grades?.length === 1) {
      if (!(produtoAtual > 0)) {//insercao
        MensagemErro('Para inserção é obrigatório pelo menos uma cor');
        return;
      }
    }

    if (produtosTemp.grades?.length > 0) {
      produtosTemp.grades.pop();
      setProdutos(produtosTemp);
    }
  }

  useEffect(async () => {
    await buscaDatasets();
    if (parseInt(produtoAtual, 10) > 0) {
      await abrirProduto(0);
    }
    else {
      await novoProduto();
    }
  }, [produtoAtual])

  const buscaGrupos = async (tipo = '') => {

    try {
      const complementoUrl = parseInt(tipo, 10) > 0 ? `&tipo_produto_id=${tipo}` : ''
      let url = `${rotafechada()}grupos_produtos?limit=-1${complementoUrl}`;
      //console.log(url);
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      let opcoesGrupoProdutoTemp = [];
      opcoesGrupoProdutoTemp.push({ ...opcaoDefaultGrupoProduto[0] });

      data.map((item) => {
        opcoesGrupoProdutoTemp.push({ id: item.id, value: item.id, label: item.nome, tipo_produto_id: item.tipo_produto_id })
      })

      setOpcoesGrupo(opcoesGrupoProdutoTemp);

      const grupoatual = getValues('grupo_produto_id');
      if (parseInt(grupoatual?.tipo_produto_id, 10) !== parseInt(tipo, 10)) {
        setValue('grupo_produto_id', opcaoDefaultGrupoProduto[0]);
      }
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }

  }

  const tipo_produto_id = useWatch({
    control,
    name: "tipo_produto_id",
  });

  const handleClickVoltar = () => {
    navigate(`/produtos/produto/`, { replace: true });
    setProdutoAtual('');
    setAba(0);
  }

  useEffect(async () => {
    //console.log('Alterou tipo_produto_id:', tipo_produto_id);
    if ((tipo_produto_id) && (parseInt(tipo_produto_id?.value, 10) > 0)) {
      await buscaGrupos(tipo_produto_id.value ? tipo_produto_id.value : '');
    }
    else setOpcoesGrupo(opcaoDefaultGrupoProduto);
  }, [tipo_produto_id])


  const getNomeProduto = useWatch({
    control,
    name: "nome",
  });


  const handleClickOpcoes = () => {
    const items = [
      { value: 0, label: 'Substituir Produto em todos os Projetos' },
      { value: 1, label: 'Replicar Produto Atual' },
    ];
    setOpcoes({ ...opcoes, open: true, tipo: 'opcoes', items });
  }

  const onCloseDialogoOpcoes = (value) => {

    setOpcoes({ ...opcoes, open: false, tipo: '' });

    if (opcoes.tipo === 'opcoes') {
      switch (parseInt(value, 10)) {
        case 0:
          if (!parseInt(produtoAtual, 10) > 0) {
            MensagemErro('Escolha o produto que será substituído!');
            return;
          }
          setOpenFiltroSubstituicaoProdutoProjeto({ open: true })
          break;
        case 1:
          if (!parseInt(produtoAtual, 10) > 0) {
            MensagemErro('Escolha o produto que será replicado!');
            return;
          }
          replicarProduto()
          break;

        default:
          break;
      }
    }
  }

  const substituiProdutoProjetos = async (produtoDestino) => {

    if (!(parseInt(produtoDestino, 10) > 0)) {
      MensagemErro('É necessário escolher o produto destino!');
      return;
    }

    setOpenFiltroSubstituicaoProdutoProjeto({ open: false })

    try {
      MostraTelaEspera('Aguarde o processamento....');
      let url = `${rotafechada()}produtos/substituiprodutoprojetos`;
      const retorno = await api.post(url, {
        produtoorigem: produtoAtual,
        produtodestino: produtoDestino
      });
      FechaTelaEspera();
      MensagemAviso('Produto substituído com Sucesso!');
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }


  const replicarProduto = async () => {

    try {
      MostraTelaEspera('Aguarde o processamento....');
      let url = `${rotafechada()}produtos/replicar/${produtoAtual}`;
      const retorno = await api.post(url);
      FechaTelaEspera();
      MensagemAviso(retorno?.data?.msg || 'Replicado com Sucesso!');
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickAlteraCores = async (produto_preco_id) => {

    try {
      await api.put(`${rotafechada()}produto_precos/replicaprecooutrascores/${produto_preco_id}`, {});
      setRecarregaTabela(!recarregaTabela);
      MensagemAviso('Alterado');
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }

  }

  const handleClickAlteraTabelasCores = async (produto_grade_id) => {

    try {
      await api.put(`${rotafechada()}produto_precos/replicaprecooutrascoresntabelas/${produto_grade_id}`, {});
      setRecarregaTabela(!recarregaTabela);
      MensagemAviso('Alterado');
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }

  }
  const handleClickCopiaPrecos = async (produto_grade_id) => {

    if (!(parseInt(produto_grade_id, 10) > 0)) {
      return;
    }

    setDialogoCopiaPrecos({ ...dialogoCopiaPrecos, produto_grade_id, open: true });
  }


  const copiaPrecosOutrasCores = async () => {


    const { produto_grade_id } = dialogoCopiaPrecos;
    setDialogoCopiaPrecos({ ...dialogoCopiaPrecos, open: false, produto_grade_id: '' });

    if (!(parseInt(produto_grade_id, 10) > 0)) {
      return;
    }

    try {
      let url = `${rotafechada()}produto_precos?limit=-1&produto_grade_id=${produto_grade_id}`;
      const retorno = await api.get(encodeURI(url));

      if (!(retorno.data?.countAll > 0)) {
        MensagemErro('O produto na cor escolhida não possui preços cadastrados');
        return;
      }

      //console.log('****',retorno.data)

      if (retorno.data?.countAll === 1) {
        //faco a requisicao passando o id da Produto_precos
        handleClickAlteraCores(retorno.data?.data[0].id);
        return;
      }else{
        //tem várias tabelas de preço
        handleClickAlteraTabelasCores(produto_grade_id)
        return
      }

      //Abrindo um dialog para escolher qual tabela de preço será alterada nas outras cores
      MensagemErro('A cor escolhida tem mais de uma tabela de preço!');

    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  return (
    <MDBox p={2}>

      <DialogoConfirma
        open={dialogoCopiaPrecos.open || false}
        message='Certeza que deseja copiar os preços dessa cor para as demais?'
        handleCancel={() => setDialogoCopiaPrecos({ ...dialogoCopiaPrecos, open: false })}
        handleConfirm={() => copiaPrecosOutrasCores()}
        title='Confirma Alteração'
      />

      <DialogoOpcoes
        value={''}
        open={opcoes.open || false}
        onClose={onCloseDialogoOpcoes}
        options={opcoes.items || []}
      />

      <FiltroSubstituicaoProdutoProjeto
        open={openFiltroSubstituicaoProdutoProjeto.open || false}
        setOpen={setOpenFiltroSubstituicaoProdutoProjeto}
        handleOk={substituiProdutoProjetos}
        produtoAtual={produtoAtual}
        nomeProdutoAtual={getNomeProduto}
      />

      <DialogoConfirma
        open={dialogoOpen.visible}
        title={'Confirma Exclusão'}
        color={'error'}
        message={'Certeza que deseja excluir?'}
        handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
        handleConfirm={excluirRegistroCor}
      />

      <MDBox width="100%" display="flex" justifyContent="space-between">
        <MDButton
          variant="gradient"
          color="secondary"
          type="button"
          onClick={handleClickVoltar}
        >Voltar</MDButton>
        <MDButton
          variant="gradient"
          circular
          color="info"
          type="button"
          onClick={props.handleClickNovo}
        >
          <Icon>add</Icon>
        </MDButton>
      </MDBox>


      <form onSubmit={handleSubmit(onSubmit)}>
        <Cabecalho
          control={control}
          opcoesTipo={opcoesTipo}
          opcoesGrupo={opcoesGrupo}
          opcoesUnidadeMedida={opcoesUnidadeMedida}
          opcoesAtivo={opcoesAtivo}
          produtos={produtos}
          getValues={getValues}
          setValue={setValue}
          produtoAtual={produtoAtual}
          setProdutoAtual={setProdutoAtual}

        />

        {produtos.grades?.map((itemCor, index) => {
          return <Grades
            control={control}
            setValue={setValue}
            getValues={getValues}
            indiceVetor={index}
            itemCor={itemCor}
            opcoesCor={opcoesCor}
            produtos={produtos}
            register={register}
            key={`grade${index}`}

          />
        })
        }

        <MDBox width="100%" display="flex" justifyContent="space-between">
          <MDBox width='200'>
            <MDButton
              variant="gradient"
              color="info"
              type="button"
              onClick={handleClickNovaCor}
            >
              + Cor
            </MDButton>
            {
              (produtos?.grades?.length > 0) ?
                <MDButton
                  sx={{ ml: 1 }}
                  variant="gradient"
                  color="error"
                  type="button"
                  onClick={handleClickExcluirItemCor}
                >- Cor</MDButton>
                : false
            }
          </MDBox>

          <MDBox>
            <MDButton
              variant="gradient"
              color="dark"
              onClick={handleClickOpcoes}
              sx={{ mr: 4 }}
            >
              Opções
            </MDButton>
            <MDButton
              variant="gradient"
              color="info"
              type="submit"
            >
              Gravar
            </MDButton>
          </MDBox>
        </MDBox>
      </form>

      {
        produtoAtual > 0 ?
          <>
            <MDBox width="100%" m={2}>
              <IconButton aria-label="expand row" size="small" onClick={() => setOpenCollapseProjeto(!openCollapseProjeto)}>
                {openCollapseProjeto ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                <Typography variant='h6' mb={2}>Projetos</Typography>
              </IconButton>
              <Collapse in={openCollapseProjeto} timeout="auto" unmountOnExit>
                <ProjetosProduto
                  produtoAtual={produtoAtual}
                />

              </Collapse>
            </MDBox>

            <MDBox width="100%" display="flex" justifyContent="space-between" mt={5}>
              <Tabela
                produtoAtual={produtoAtual}
                abrirRegistroCor={abrirRegistroCor}
                excluirRegistroCor={handleClickExcluirCor}
                recarregaTabela={recarregaTabela}
                handleClickCopiaPrecos={handleClickCopiaPrecos}
              />
            </MDBox>
          </>
          : false
      }




    </MDBox>
  )

}

export default Cadastro;
