import React, { useContext, useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MessagesContext from 'hooks/MessagesContext';
import AuthContext from "hooks/AuthContext";
import { useForm, useWatch } from 'react-hook-form';
import MDButton from 'components/MDButton';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import Cabecalho from './cabecalho';
import TiposPessoas from './tipospessoas';
import PessoaContatos from './pessoacontatos';
import PessoaEnderecos from './pessoaenderecos';
import { Icon } from "@mui/material";
import { useNavigate } from 'react-router-dom';


function Cadastro(props) {

  const navigate = useNavigate();

  const { pessoaAtual, setPessoaAtual, setAba } = props;
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const { handleSubmit, control, setValue, getValues, setFocus } = useForm({});
  const [parametrosPessoa, setParametrosPessoa] = useState({});
  const [foco, setFoco] = useState(false)

  const [opcoesAtivo, setOpcoesAtivo] = useState([
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'S', label: 'Sim' },
    { value: 'N', label: 'Não' },
  ]);
  const [opcoesTipoTelefone, setOpcoesTipoTelefone] = useState([
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'C', label: 'Celular' },
    { value: 'F', label: 'Fixo' },
    { value: 'W', label: 'WhatsApp' },
  ]);
  const [opcoesFisica_juridica, setOpcoesFisica_juridica] = useState([
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'F', label: 'Fisica' },
    { value: 'J', label: 'Juridica' },
  ]);
  const [opcoesProdutor_rural, setOpcoesProdutor_rural] = useState([
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'S', label: 'Sim' },
    { value: 'N', label: 'Não' },
  ]);
  const [recarregaComponenteOpcoes, setRecarregaComponenteOpcoes] = useState(false);

  const onSubmit = (data) => {
    handleClickGravar(data);
  }

  const limpaInputs = () => {
    setValue('id', '');
    setValue('fisica_juridica', { value: 'F', label: 'Fisica' });
    setValue('produtor_rural', { value: 'N', label: 'Não' });
    setValue(`ativo`, { value: 'S', label: 'Sim' });
    setValue(`razao_social`, '');
    setValue(`fantasia`, '');
    setValue(`cpf_cnpj`, '');
    setValue(`rg_ie`, '');
    setValue(`inscricao_municipal`, '');
    setValue(`observacao`, '');
  }

  const validaCampos = (data) => {

    let camposObrigatorios = ``;

    // if (!data.fantasia) {
    //   camposObrigatorios = `${camposObrigatorios}/Fantasia`
    // }

    if (!data.ativo) {
      camposObrigatorios = `${camposObrigatorios}/Ativo`
    }

    if (camposObrigatorios !== '') {
      throw new Error(`Campos obrigatórios: ${camposObrigatorios}`)
    }
  }

  const handleClickGravar = async (data) => {

    //tratando os campos autocomplete
    data.fisica_juridica = data.fisica_juridica?.value ? data.fisica_juridica.value : '';
    data.produtor_rural = data.produtor_rural?.value ? data.produtor_rural.value : '';
    data.ativo = data.ativo?.value ? data.ativo.value : '';

    try {
      validaCampos(data);
    }
    catch (error) {
      MensagemErro(`${error.message}`);
      return;
    }

    try {
      const dados = { empresa_id: sessaolocal.empresa_id || null };

      //pegando do OBJ do RHF apenas os campos que sao do projeto
      dados.fisica_juridica = data.fisica_juridica;
      dados.produtor_rural = data.produtor_rural;
      dados.ativo = data.ativo;
      dados.razao_social = data.razao_social;
      dados.fantasia = data.fantasia;
      dados.cpf_cnpj = data.cpf_cnpj;
      dados.rg_ie = data.rg_ie;
      dados.inscricao_municipal = data.inscricao_municipal;
      dados.observacao = data.observacao;

      let retorno;
      let codigoPessoa = '';

      if (parseInt(pessoaAtual, 10) > 0) {
        retorno = await api.put(`${rotafechada()}pessoas/${pessoaAtual}`, dados);
        codigoPessoa = pessoaAtual;
        setRecarregaComponenteOpcoes(!recarregaComponenteOpcoes);
        setFoco(false)
        MensagemAviso(`Alterado com sucesso! Pessoa: ${codigoPessoa}`);
        
      }
      else {
        retorno = await api.post(`${rotafechada()}pessoas`, dados);
        codigoPessoa = retorno.data.data.id;
        setFoco(true)
        MensagemAviso(`Gravado com sucesso! Pessoa: ${codigoPessoa}.
        Escolha um tipo ! `, 10);
      }


      //se for um novo produto entao "recarrego" a pagina
      if (codigoPessoa !== pessoaAtual) {
        setPessoaAtual(codigoPessoa);
      }
    }
    catch (error) {
      console.log(error);
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const novapessoa = async () => {
    limpaInputs();
    setPessoaAtual('');
  }

  const abrirPessoa = async () => {
    // console.log('abrir pessoa:', pessoaAtual);
    try {
      let url = `${rotafechada()}pessoas?id=${pessoaAtual}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      if (!data.length > 0) {
        MensagemErro(`Pessoa ${pessoaAtual} não localizada`);
        return;
      }
      //console.log("DATA",data)
      limpaInputs();

      setValue('id', data[0].id);
      setValue(`fisica_juridica`, { value: data[0].fisica_juridica === 'F' ? 'F' : 'J', label: data[0].fisica_juridica === 'F' ? 'Fisica' : 'Juridica' });
      setValue(`produtor_rural`, { value: data[0].produtor_rural === 'S' ? 'S' : 'N', label: data[0].produtor_rural === 'S' ? 'Sim' : 'Não' });
      setValue(`ativo`, { value: data[0].ativo === 'S' ? 'S' : 'N', label: data[0].ativo === 'S' ? 'Sim' : 'Não' });
      setValue('razao_social', data[0].razao_social);
      setValue('fantasia', data[0].fantasia);
      setValue('cpf_cnpj', data[0].cpf_cnpj);
      setValue('rg_ie', data[0].rg_ie);
      setValue('inscricao_municipal', data[0].inscricao_municipal);
      setValue('observacao', data[0].observacao);
      setRecarregaComponenteOpcoes(!recarregaComponenteOpcoes);

    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }


  const buscaParametros = async () => {

    try {
      let url = `${rotafechada()}pessoas/parametros`;
      const retorno = await api.get(encodeURI(url));
      const data = retorno.data;

      setParametrosPessoa(data);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickVoltar = () => {
    navigate(`/pessoas/pessoa/`, { replace: true });
    setPessoaAtual('');
    setAba(0);
  }

  useEffect(async () => {
    //await buscaDatasets();
    if (parseInt(pessoaAtual, 10) > 0) {
      await abrirPessoa();
    }
    else {
      await novapessoa();
    }
  }, [pessoaAtual]);

  useEffect(() => {
    buscaParametros()
  }, [])

  return (
    <MDBox p={2}>
      <MDBox width="100%" display="flex" justifyContent="space-between">
        <MDButton
          variant="gradient"
          color="secondary"
          type="button"
          onClick={handleClickVoltar}
        >Voltar</MDButton>
        <MDButton
          variant="gradient"
          circular
          color="info"
          type="button"
          onClick={novapessoa}
        >
          <Icon>add</Icon>
        </MDButton>
      </MDBox>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Cabecalho
          control={control}
          opcoesFisica_juridica={opcoesFisica_juridica}
          opcoesProdutor_rural={opcoesProdutor_rural}
          opcoesAtivo={opcoesAtivo}
          getValues={getValues}
        />
        <MDBox width="100%" display="flex" justifyContent="flex-end">
          <MDButton
            variant="gradient"
            color="info"
            type="submit"
          >
            Gravar
          </MDButton>
          {/* <MDBox ml={2}>
            <MDButton variant="gradient" color="info" onClick={novapessoa}>
              Novo
            </MDButton>
          </MDBox> */}
        </MDBox>
      </form>
      {
        parseInt(pessoaAtual, 10) > 0 ?
          <TiposPessoas
            control={control}
            getValues={getValues}
            setValue={setValue}
            pessoaAtual={pessoaAtual}
            handleSubmit={handleSubmit}
            recarregaComponenteOpcoes={recarregaComponenteOpcoes}
            foco={foco}
          />
          : false
      }
      {
        parseInt(pessoaAtual, 10) > 0 ?
          <PessoaContatos
            control={control}
            getValues={getValues}
            setValue={setValue}
            pessoaAtual={pessoaAtual}
            opcoesTipoTelefone={opcoesTipoTelefone}
            handleSubmit={handleSubmit}
            recarregaComponenteOpcoes={recarregaComponenteOpcoes}
          />
          : false
      }
      {
        parseInt(pessoaAtual, 10) > 0 ?
          <PessoaEnderecos
            control={control}
            getValues={getValues}
            setValue={setValue}
            pessoaAtual={pessoaAtual}
            handleSubmit={handleSubmit}
            recarregaComponenteOpcoes={recarregaComponenteOpcoes}
            parametrosPessoa={parametrosPessoa}
          />
          : false
      }
    </MDBox>
  )

}

export default Cadastro;
